import "babel-polyfill";
import "es6-promise/auto";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import "./registerServiceWorker";
import "@/styles/common.scss";
import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "element-ui";
import { Collapse, InfiniteScroll } from "element-ui";
import Datepicker from "vuejs-datepicker";
import Tabs from "vue-material-tabs";
import locale from "element-ui/lib/locale/lang/ko";
import "moment/locale/ko";
import i18n from "./lang";
// 캘린더
import VCalendar from 'v-calendar';
// 스케쥴
// import Calendar from '@toast-ui/vue-calendar';
// import '@toast-ui/calendar/dist/toastui-calendar.min.css';

Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
Vue.use(Collapse);
Vue.use(InfiniteScroll);
Vue.use(Datepicker);
Vue.use(Tabs);
Vue.use(VCalendar);

window.VueInstance = new Vue({
  router,
  store,
  i18n,
  data : {
  },
  methods:{
  },
  created() {
  },
  beforeCreate() {
    if(localStorage.getItem("sessionTime") != null) {
      let time = localStorage.getItem("sessionTime")
      if (new Date().getTime() - time > 180.0 * 1000) {
        localStorage.removeItem("token");
      }
    }

    setInterval(() => {
      if(localStorage.getItem("sessionTime") != null) {
        localStorage.setItem("sessionTime", new Date().getTime());
      }
    },1000);

    if (this.$store.state.store.isLogin) {
      this.$store.dispatch("store/getAccountInfo");
    }
  },
  beforeDestroy() {
    for (var i = 1; i < 99999; i++) {
      window.clearInterval(i);
    }
  },

  render: (h) => h(App),
}).$mount("#app");
