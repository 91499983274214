export default {
  "footer-data-contact-us" : "이용문의",
  "footer-data-company-name": "테이슨",
  "footer-data-company-tel": "문의전화",
  "footer-data-company-email": "이메일",

  "btn-login": "로그인",
  "btn-sign-in": "회원가입",

  5: "Guest로 참여",

  "login-info-registered-id": "회원가입 시 등록한 아이디(메일 주소)와",
  "login-info-registered-pw": "비밀번호를 입력해주세요",

  "login-data-id": "아이디(이메일 주소)",
  "login-data-pw": "비밀번호",
  "login-warning-comment": "아직 회원이 아니신가요?",
  //10: "자동 로그인",

  //13: "가입하지 않은 아이디 혹은 잘못된 비밀번호입니다",


  19: "Guest로 참여",
  "20-1": "Guest로 참여하시려면",
  "20-2": "이름, 핸드폰, 소속을 입력해주세요",

  "login-info-available": "본 시스템은 인가된 사용자만 사용할 수 있으며,",
  "login-info-available-illegally": "불법으로 사용시 법적 제재를 받을 수 있습니다.",

  26: "참여하기",

  //32: "아래 정보를 입력해 주세요",
  "login-info-approval": "가입 신청 시 담당자 승인이 완료된 이후 로그인이 가능합니다. 계정 승인 시 이메일로 승인 안내해 드립니다",

  //38: "(영문, 숫자, 특수기호를 2개 이상 조합하여 8자리 이상으로 입력해주세요)",

  "sign-in-info-done": "정보 입력 완료! 가입 승인 대기중입니다",
  "sign-in-info-done-approval": "가입 승인은 평균 1~2일정도 소요됩니다. ",
  "sign-in-info-done-email": "가입승인 완료시 아이디로 입력해주신",
  "sign-in-info-done-send-email": "메일주소로 안내 메일을 발송해 드립니다.",
  "sign-in-info-done-emergency": "긴급승인이 필요한 경우에는 담당자에게 연락 바랍니다. ",
  "sign-in-info-done-wait": "가입 승인 대기중입니다",
  "btn-re-login": "재로그인",
  "btn-find-id" : "아이디 찾기",

  "find-id-info-enter-data": "아이디를 찾으려면 이름과 핸드폰 정보를 입력해주세요.",
  "find-pw-info-enter-data": "비밀번호를 찾으려면 아이디와 이름과 핸드폰 정보를 입력해주세요.",

  "find-id-title": "아이디 찾기",

  "alert-message-not-found-user": "해당 정보로 가입한 회원정보를 찾을 수 없습니다.",
  "alert-message-manager-question": "정보가 올바르지 않을 경우 담당자에게 문의해주세요.",

  //71: "아이디를 찾으려면 이름과 핸드폰 정보를 입력해주세요",
  //76: "(이름) 정보를 입력해 주세요",
  //77: "(핸드폰) 정보를 입력해 주세요",
  //78: "해당 정보로 가입한 회원정보를 찾을 수 없습니다",
  //79: "정보가 올바르지 않을 경우 담당자에게 문의해주세요",

  "find-id-info-email": "찾으려는 아이디(메일주소)는",
  "find-id-info-email-2": "입니다.",

  "find-id-info-contact-info": "아이디(메일주소) 전체 정보 확인이 필요한 경우 담당자에게 문의해 주세요",

  "btn-find-pw": "비밀번호 찾기",
  "find-pw-title": "비밀번호 찾기",

  //86: "비밀번호를 찾으려면 아이디, 이름, 핸드폰 정보를 입력해주세요",

  //100: "아이디 정보를 입력해 주세요",
  //101: "이름 정보를 입력해 주세요",
  //102: "휴대폰 정보를 입력해 주세요",

  "alert-message-sending-email": "회원님의 메일로 비밀번호 재설정 안내 메일을 발송하였습니다",
  "alert-message-confirm-email": "회원가입시 입력한 이메일을 확인해주세요.",
  "alert-message-info-find-pw": "[비밀번호 재설정] 링크를 클릭하여 새 비밀번호를 설정하실 수 있습니다.",

  "btn-login-done": "로그인하기",
  "alert-message-not-send-email": "메일이 오지 않을 경우 담당자에게 문의해 주세요",

  112: "변경할 비밀번호를 입력해 주세요",

  115: "비밀번호는 영문, 숫자, 특수기호를 2개 이상 조합하여 8자리 이상으로 입력해주세요",

  116: "비밀번호 정보를 입력해 주세요",
  117: "비밀번호 정보를 형식에 맞게 입력해 주세요",
  118: "비밀번호와 재확인 비밀번호가 다릅니다. 다시 입력해주세요",

  120: "비밀번호 변경",
  121: "정보가 올바르지 않을 경우 담당자에게 문의해주세요",

  "alert-pw-change-complete": "비밀번호 재설정이 완료되었습니다.",
  "alert-pw-change-check-key-fail": "만기된 URL 입니다.",
  124: "로그인 후 이용해주세요",

  126: "휴면해제",

  127: "현재 회원님 계정은 장기 미사용으로 휴면상태입니다",
  128: "지금 휴면을 해제하시겠습니까",

  130: "해제하기",
  131: "이용정지 안내",

  132: "회원님은 현재 이용정지 상태입니다",
  133: "담당자에게 문의해 주세요",
  134: "확인",

  142: "비밀번호는 영문, 숫자, 특수기호를 2개 이상 조합하여 8자리 이상으로 입력해주세요",

  146: "회원탈퇴 후 재가입시 관리자의 재승인이 필요합니다",
  148: "지금 회원탈퇴 하시겠습니까",
  149: "관리자 권한의 회원은 탈퇴가 불가합니다. 권한 변경 후 탈퇴해주세요",


  //좌측 메뉴
  "lnb-user-mark": "사용자 메뉴",
  "lnb-manager-mark": "운영자 메뉴",
  "lnb-admin-mark": "관리자 메뉴",
  "lnb-eventController-mark": "종합 메뉴",

  "overall-main-home": "대시보드",
  "overall-site-control": "드론 비교 영상",
  "overall-site-control-Admin": "관리자 홈",
  "overall-site-control-user": "종합요약정보",
  "overall-lnb-site-manage": "현장 관리",

  //site control 좌측 메뉴
  "site-control-menu-main":"현장 대시보드",
  "site-control-main-sub-sky-view":"현장 상황판",
  "site-control-main-sub-info":"현장정보",

  "site-control-menu-data-contents":"콘텐츠", //현장상황판
  "site-control-menu-contents":"영상 데이터",
  "site-control-contents-sub-vod":"드론 비행 영상",
  "site-control-contents-sub-Comparison":"드론 비교 영상",
  "site-control-contents-sub-live":"라이브 멀티뷰",
  "site-control-contents-sub-live-map":"라이브 맵",
  "site-control-contents-sub-mission-vod":"자동 경로비행 영상",

  "site-control-menu-device":"디바이스",
  "site-control-device-sub-drone":"드론",
  "site-control-device-sub-drone-station":"드론 스테이션",
  "site-control-ai-monitoring":"디텍팅 모니터링",
  "site-control-device-sub-drone-title":"드론 운영 이력",
  "site-control-device-sub-flight-title":"비행 기록 이력",

  "site-control-menu-point":"중요 위치점",
  "site-control-menu-member":"현장인원",
  "site-control-menu-notice":"업무 공지",

  "site-control-menu-mapping":"맵핑",
  "site-control-mapping-sub-list":"맵핑 목록",
  "site-control-mapping-sub-create":"맵핑 등록",

  "site-control-menu-plan":"드론경로계획",
  "site-control-menu-mission":"웨이포인트 미션",
  "site-control-mission-sub-list":"웨이포인트 목록",
  "site-control-mission-sub-detail":"웨이포인트 상세보기",
  "site-control-mission-sub-update":"웨이포인트 수정",
  "site-control-mission-sub-create":"웨이포인트 등록",
  "site-control-mission-sub-upload":"경로 업데이트",
  "site-control-menu-report":"보고서",

  "site-control-menu-station-plan":"스테이션계획",
  "site-control-menu-schedule":"스케줄 관리",
  "site-control-menu-schedule-create":"스케줄 이벤트 등록",
  "site-control-menu-schedule-data-create":"스케줄 등록",
  "site-control-menu-schedule-data-update":"스케줄 상세보기",
  "site-control-menu-schedule-history":"스케줄 이력",
  "site-control-menu-schedule-history-Detail":"스케줄 이력 상세",

  //top selectBox menu name
  "top-select-total-view-dashboard": "종합 요약 정보",
  "top-select-total-drone-status-map": "종합상황판",
  "top-select-total-drone-map-monitoring": "통합 모니터",
  "top-select-site-map-monitoring": "현장 모니터",
  "top-select-total-view-drone": "참여 디바이스 목록",
  "top-select-total-view-vod": "드론 비행영상 목록",
  "top-select-total-view-flight-vod": "비행영상목록",

  "top-select-total-view-live": "라이브 목록",
  "top-select-site-view-list": "현장 목록 ",
  "top-select-user-view-list": "사용자 목록 ",
  "top-select-site-view-list-set": "상세 설정 ",
  "top-select-site-view-list-sort-view-id": "일련번호",
  "top-select-site-view-list-sort-site-name": "현장명",
  "top-select-site-view-list-sort-manager-department-title": "소속",
  "top-select-site-view-list-sort-reg-date": "등록일",

  "all-vod-list-replay-title": "전체 영상",

  "top-select-site-statistics-site": "현장 발생건수",
  "top-select-site-statistics-member": "현장 참여자수",
  "top-select-site-statistics-direction": "지역별 현장수",

  "top-select-site-manager": "현장 관리",
  "top-select-live-data-ch": "라이브 채널 관리",
  "top-select-live-data-ch-list": "라이브 채널 목록",
  "top-select-station-data": "스테이션 관리",

  "top-select-ai-live-data-ch": "AI 라이브 채널 관리",
  "top-select-ai-vod-data-list": "AI 정밀 분석 영상 관리",
  "top-select-ai-Streaming-data-list": "AI 영상 관리",

  "top-select-ai-live-data-detail-ch": "AI 라이브 상세화면",
  "top-select-ai-detail-vod-data-list": "AI 정밀 영상 상세확인",
  "top-select-ai-detail-Streaming-data-list": "AI 스트리밍 상세확인",

  "top-select-live-data-details": "LIVE 채널 상태확인",

  "top-select-live-data-monitor": "LIVE 모니터링",
  "top-select-live-video-monitor": "LIVE 영상 모니터링",
  "top-select-live-data-allocation": "채널할당내역",

  "site-view-mission-title":"현장 미션관리",
  "site-view-mission-choice-title":"미션관리 현장 선택",
  "site-view-mission-flight-title":"현장 자동 경로비행",

  "site-view-contents-title":"현장 콘텐츠관리",
  "site-view-contents-choice-title":"콘텐츠 현장 선택",

  "site-view-monitoring-choice-title":"현장별 상황판 목록",
  "site-view-monitoring-title":"현장 대시보드",

  "top-select-vod-manager-contents": "영상 데이터 관리",
  "top-select-vod-manager-tc": "트랜스코딩내역",

  "vod-detail-title-contents": "영상데이터 상세확인",

  "top-select-device-list": "디바이스 목록",
  "top-select-device-manager": "디바이스 자산관리",
  "top-select-device-manager-drone": "드론 자산관리",
  "top-select-device-manager-details": "드론 운영로그",
  "top-select-user-dept-manager": "회원/소속 관리",
  "top-select-user-manager": "회원 관리",
  "top-select-user-manager-dept": "소속 관리",
  "top-select-user-manager-member-log": "회원 접속로그",

  "top-select-user-confirm-manager": "회원 승인관리",

  //사용자 홈
  "user-home-event-choice":"현장 목록",
  "user-home-user-info-title":"접속자 정보",
  "user-home-user-system-notice":"시스템 공지사항",
  "user-home-user-system-board-download":"다운로드 게시판",
  "user-home-user-system-notice-title":"제목",
  "user-home-user-system-notice-contents":"내용",
  "user-home-user-system-notice-date":"일시",
  "user-home-site-on-progress":"진행중",
  "user-home-site-sort-order":"정렬순서",
  "user-home-site-sort-by-viewId":"일련번호",
  "user-home-site-sort-by-site-name":"현장명",
  "user-home-site-sort-by-manager-department-title":"소속",

  "user-home-user-version-notice-register-title": "앱 버전 등록",
  "user-home-user-version-notice-info-title": "앱 버전 정보",
  "user-home-user-version-notice-please-input-version": "버전을 입력하세요.",
  "user-home-user-version-app-type": "앱 구분",
  "user-home-user-version-app-version": "버전",
  "user-home-user-version-app-is-latest": "최신여부",
  "user-home-user-version-app-is-open": "공개여부",
  "user-home-user-version-app-open": "공개",
  "user-home-user-version-app-type-viewer": "뷰어",
  "user-home-user-version-app-type-controller": "컨트롤러",
  "user-home-user-version-app-answer-yes": "예",
  "user-home-user-version-app-answer-no": "아니오",
  "user-home-user-version-app-release-note": "릴리즈 노트",
  "user-home-user-version-app-please-input-release-note": "릴리즈 노트를 입력하세요.",
  "user-home-user-version-app-please-select-app-type": "앱 구분을 선택해주세요.",
  "user-home-user-version-app-please-input-version": "버전을 입력해주세요.",

  //user title
  "user-info-edit":"개인정보 수정",
  "user-detail-title":"회원 정보 상세",
  "user-register-title":"신규 회원 등록",
  "user-dept-detail-title":"소속 정보 상세",
  "user-dept-register-title":"신규 소속 등록",

  "drone-detail-title":"디바이스 정보 상세",
  "drone-register-title":"신규 드론 등록",

  "site-detail-title":"현장 정보 상세",
  "station-detail-title":"스테이션 정보 상세",
  "site-register-title":"신규 현장 등록",

  "btn-logout": "로그아웃",

  //mypage title
  "user-data-pw-change": "비밀번호 변경",
  "user-data-pw-confirm": "비밀번호 재확인",
  "user-data-pw-input-instruction" : "영문, 숫자, 특수기호를 2개 이상 조합하여 8자리 이상으로 입력해주세요.",
  "user-data-pw-input-correct-msg" : "비밀번호를 정확하게 입력해주세요.",

  "top-mypage-pop-confirm-question": "정보를 변경하시겠습니까?",
  "top-mypage-pop-confirm-success": "정보가 변경되었습니다,",


  //button_txt
  "btn-data-change": "변경하기",
  "btn-data-patch": "수정",
  "btn-cancel": "취소",
  "btn-refresh": "새로고침",
  "btn-new-browser": "새창으로 보기",
  "btn-txt-more": "더보기",
  "btn-vod-new-browser": "새창보기",
  "btn-searching": "검색",
  "btn-reset": "초기화",
  "btn-event-list": "현장 전체목록",
  "btn-register": "등록",
  "btn-notice-delete": "내용삭제",
  "btn-delete": "삭제",
  "btn-update" : "수정",
  "btn-site-register": "현장등록",
  "btn-confirm": "확인",
  "btn-select": "선택확인",
  "btn-detail": "상세보기",
  "btn-detail-view": "선택",
  "btn-add": "추가",
  "btn-confirm-close": "닫기",
  "btn-restore": "삭제취소",

  //select default choose
  "select-box-default-choose":"--- 선택하세요 ---",


  //dashboard count
  "overall-data-event": "진행중 현장",
  "overall-data-event-ea":"곳",
  "overall-data-device": "참여 디바이스",
  "overall-data-register-device": "등록된 디바이스",
  "overall-data-device-ea":"대",
  "overall-data-member": "참여 멤버",
  "overall-data-site-member": "인원이력",
  "overall-data-member-ea":"명",
  "overall-data-live": "할당된 라이브 채널",
  "overall-data-vod": "진행중 현장 영상",
  "overall-data-num-ea":"회",
  "overall-data-default-ea":"개",
  "overall-data-point": "",

  "overall-data-now-device": "실시간 드론",
  "overall-data-now-member": "실시간 인원",
  "overall-data-now-device-num": "실시간 드론수",
  "overall-data-cnt-log-drone": "드론비행횟수",
  "overall-data-cnt-log-vod": "비행영상목록",
  "overall-data-cnt-log-member": "인원참여횟수",

  //data count
  "data-total" : "총",
  "data-default-ea":"개",
  "data-case-ea":"건",
  "data-vod":"영상",
  "data-ch":"채널",
  "data-drone-flight":"드론 비행",
  "data-member-chk-in":"현장인원 체크인",
  "data-today":"현재",


  //dashboard_overall
  "overall-count-title": "진행중 현장 주요건수",
  "overall-all-count-title": "현장 주요건수",
  "overall-map-title": "현재 지역별 현장건수",
  "overall-event-table-title": "진행중인 현장 목록",
  "overall-event-table-title-dist": "지역",
  "overall-event-table-title-event-name": "현장명",
  "overall-event-table-title-user-num": "참여자",
  "overall-event-table-title-live-num": "Live",
  "overall-event-table-title-vod-num": "VOD",
  "overall-event-table-title-start-date": "발생일",

  "report-drone-flight": "드론 운용 기록",
  "report-drone-video": "드론 영상 기록",


  "overall-drone-title": "드론 운영 이력",
  "overall-device-list-title": "디바이스 자산목록",

  "overall-vod-title": "최근 드론 비행영상",
  "overall-live-title": "최근 LIVE 채널",
  "overall-chart-device-title" : "Site device",
  "overall-chart-support-title" : "Site user",
  "overall-chart-event-title" : "현장 등록건수",

  //현장별 상황판 카운트
  "site-monitoring-home-live-flag":"LIVE 방송중",
  "site-monitoring-home-data-drone": "드론",
  "site-monitoring-home-data-member": "멤버",
  "site-monitoring-home-data-point": "중요 위치점",
  "site-monitoring-home-data-vod": "동영상",
  "site-monitoring-home-data-log-drone": "비행기록",
  "site-monitoring-home-data-log-member": "이동기록",

  "site-monitoring-record-member-list-status-true-name": "참여중",
  "site-monitoring-record-member-list-status-false-name": "종료",

  "vod-list-title": "VOD 목록",
  "live-map-title": "LIVE 채널 송출 위치표시",
  "liveStatus-list-title": "전체 LIVE 목록",


  //search_title
  "search-keyword-title": "검색조건",
  "search-keyword-all": "전체",
  "search-regdate-title": "등록일",

  //event(site) status
  "event-data-status-all": "전체",
  "event-data-status-ready": "대기",
  "event-data-status-on-going": "진행중",
  "event-data-status-close": "종료",



  //"search-none-result-search": "검색결과가 없습니다",
  "data-none-result": "등록된 정보가 없습니다.",
  //246: "검색어를 입력해주세요",


  //site view title
  "site-view-title-map": "현장지도",
  "site-view-title-live": "Live 현황",
  "site-view-title-vod": "최근 등록 영상",
  "site-view-map-title": "참여자 위치",
  "site-view-map-check-title": "표시 항목",
  "site-view-data-recode-title": "현장이력",
  "site-view-data-location-Player": "위치 상세",


  "site-view-data-recode-click-tabs": "각 이력을 클릭하면 우측 지도에서 이동 경로를 확인하실 수 있습니다.",
  "site-view-data-summary-copy-link": "복사하기",
  "site-view-data-summary-print-btn": "인쇄하기",

  //summary text
  "site-view-data-summary-title": "현장 개요",

  "site-view-data-summary-period": "기간",
  "site-view-data-summary-charge": "담당",
  "site-view-data-summary-participation": "총 참여 인원",
  "site-view-data-summary-participation-num": "현장 체크인 횟수",
  "site-view-data-summary-location": "위치정보",
  "site-view-data-summary-video": "영상정보",
  "site-view-data-summary-work-list": "작업내역",
  "site-view-data-summary-work-date": "일자",
  "site-view-data-summary-work-drone": "드론 수",
  "site-view-data-summary-work-operator": "조종자 수",
  "site-view-data-summary-work-flight": "비행 횟수",
  "site-view-data-summary-work-participation-member": "참여인원 수",
  "site-view-data-summary-work-live": "라이브 건 수",
  "site-view-data-summary-work-vod": "업로드 영상개수",

  //site-view-map-check-title
  "site-view-title-device":"디바이스",
  "site-view-title-drone":"드론",
  "site-view-title-operator":"조종자",
  "site-view-title-member":"실시간 인원",
  "site-view-title-user":"참여인원",
  "site-view-title-point":"중요위치점",
  "site-view-title-notice":"공지사항",


  //탭 제목
  "site-monitoring-drone-tab-list" : "드론 목록",
  "site-monitoring-drone-tab-log": "비행 기록",

  "site-monitoring-mem-tab-list":"멤버 목록",
  "site-monitoring-mem-tab-log":"이동 기록",

  "site-monitoring-station-tab-list":"스테이션 목록",
  "site-monitoring-station-tab-log":"동작 기록",

  "live-map-tab-optics-img" : "광학 라이브맵",
  "live-map-tab-thermal-img": "열화상 라이브맵",

  "title-detail-info":"상세정보",



  //253: "위치기반 검색",
  //254: "비행중인 드론 위치",

  //site-monitoring-device-station-log
  "site-monitoring-station-device":"드론명",
  "site-monitoring-station-station":"스테이션명",
  "site-monitoring-station-manager":"관리자",
  "site-monitoring-station-dept":"담당소속",
  "site-monitoring-station-phone":"연락처",
  "site-monitoring-station-operation-num":"동작횟수",


  //스테이션 동작기록
  "site-monitoring-station-start-time":"시작시간",
  "site-monitoring-station-end-time":"종료시간",
  "site-monitoring-station-operation-time":"운용시간",
  "site-monitoring-station-drone-name":"드론명",
  "site-monitoring-station-station-name":"스테이션명",


  //map-in-tab-button
  "btn-map-in-tab-current-loc": "현 위치",
  "btn-map-in-moving-route": "이동경로",

  //드론 비행 알람 팝업
  "flight-alarm-none":"알림이 없습니다.",

  //site view summary title
  "site-view-summary-event-status": "현 상태",
  "site-view-summary-event-mem": "현 참여인원",
  "site-view-summary-event-vod-num": "영상갯수",
  "site-view-summary-event-regdate": "등록일",
  //295: "뷰어앱 현장 링크",
  "site-view-summary-event-memo": "참고사항",
  "site-view-summary-system-notice": "공지사항 작성",
  "site-view-summary-system-notice-detail": "공지사항",
  "site-view-summary-system-notice-please-input-title": "제목을 입력하세요.",
  "site-view-summary-system-notice-please-input-content": "내용을 입력하세요.",
  "site-view-summary-system-notice-ask-delete-file": "다음 첨부파일을 삭제하시겠습니까?",
  "site-view-summary-system-notice-ask-delete-notice": "정말로 삭제하시겠습니까?",

  //site-view-weather
  "site-view-weather-title": "기상정보",
  "site-view-weather-update": "업데이트 시간",
  "site-view-weather-location": "정보수집위치",
  302: "남동풍",
  303: "남서풍",
  304: "북동풍",
  305: "북서풍",
  306: "동풍",
  307: "서풍",
  308: "남풍",
  309: "북풍",

  //site-view-notice
  "site-view-notice-title": "공지 발송",
  "site-view-notice-img": "이미지 등록",
  "site-view-notice-edit-text-hint": "메시지 길이는 최대 50자까지 / 사진은 1장만 가능",
  "btn-notice-send": "공지발송",
  "site-view-notice-send-list": "공지발송내역",


  "site-view-notice-table-sendtime": "발송시각",
  "site-view-notice-table-contents": "공지내용",
  "site-view-notice-table-picture": "사진",
  "site-view-data-remarks" : "비고",
  "site-view-notice-img-hint": "높이 80 또는 가로 120 픽스",
  "site-view-notice-pop-over-msg": "메시지 길이가 50자를 초과하였습니다",
  "site-view-notice-pop-enter-msg": "메시지를 입력해주세요",



  //site-statistics
  "site-statistics-title": "통계",
  "site-statistics-prev-period": "이전기간",
  "site-statistics-searching-period": "조회기간",
  "site-statistics-legend-compare": "이전 데이터(비교)",
  "site-statistics-title-compare": "이전 데이터(비교)",

  "site-statistics-select-last-1-day": "최근 1일 기준",
  "site-statistics-select-last-7-day": "최근 7일 기준",
  "site-statistics-select-last-30-day": "최근 30일 기준",
  "site-statistics-select-last-90-day": "최근 90일 기준",
  "site-statistics-select-yourself": "직접선택",

  "site-statistics-title-compare-prev": "이전 대비",
  "site-statistics-column-date": "일자",

  "site-statistics-data-last": "최근 ",
  "site-statistics-data-days": " 일",
  "site-statistics-data-graph-type": "그래프 유형선택",
  "site-statistics-data-graph-bar": "막대그래프",
  "site-statistics-data-graph-pie": "원형그래프",

  "site-statistics-select-start-date": "시작",
  "site-statistics-select-end-date": "종료",

  "site-statistics-column-outbreaks": "발생건수(건)",
  "site-statistics-column-live-time": "LIVE 중계 (시간)",

  "site-statistics-column-all-user": "참여자수(명)",
  "site-statistics-column-mem": "현장지원",
  "site-statistics-column-operator": "조종자",
  "site-statistics-column-total" : "소계",


  //지역별 통계 지역 이름
  "site-statistics-legend-seoul": "서울특별시",
  "site-statistics-legend-busan": "부산광역시",
  "site-statistics-legend-daegu": "대구광역시",
  "site-statistics-legend-incheon": "인천광역시",
  "site-statistics-legend-gwangju": "광주광역시",
  "site-statistics-legend-daejeon": "대전광역시",
  "site-statistics-legend-ulsan": "울산광역시",
  "site-statistics-legend-sejong": "세종특별자치시",
  "site-statistics-legend-gyeonggi": "경기도 ",
  "site-statistics-legend-gangwon": "강원도",
  "site-statistics-legend-chungbuk": "충청북도",
  "site-statistics-legend-chungnam": "충청남도",
  "site-statistics-legend-jeollabuk": "전라북도",
  "site-statistics-legend-jeollanam": "전라남도",
  "site-statistics-legend-gyeongbuk": "경상북도",
  "site-statistics-legend-gyeongnam": "경상남도",
  "site-statistics-legend-jeju": "제주시",


  //site(event) data name
  "event-data-site-num": "현장번호",
  "event-data-site-status": "상태",
  "event-data-site-dist":"지역",
  "event-data-site-name": "현장명",
  "event-data-site-addr": "주소",
  "event-data-site-dept": "담당소속",
  "event-data-site-manager": "담당자",
  "event-data-site-read-restrict": "열람제한",
  "event-data-site-regdate": "등록일",
  "event-data-site-phone": "연락처",
  "event-data-site-email": "이메일",
  "event-move-to-this-site": "현장 바로가기",

  "event-data-site-radio-no-restrict": "제한",
  "event-data-site-radio-restrict": "제한없음",

  "event-data-site-shut-down": "강제종료",
  "event-data-site-accessible": "열람가능 소속",

  //"391-1":"'-' 를 제외한 숫자만 입력해주세요.",
  //393: "현장체크인 비밀번호",
  //394: "숫자 6자리, 컨트롤러, 뷰어 앱에서 게스트로 참여시 비밀번호 입력 필수",
  //395: "현장 목록 숨김",
  //396: "컨트롤러, 뷰어 앱에서 현장목록 숨김, 공유url로만 접근 가능",

  "event-data-site-restrict-info": "정보 열람 제한",
  "event-data-site-restrict-description": "현장별 상황판, 현장등록 메뉴에서 상세보기 제한",


   //station data name
   "station-data-stationName": "스테이션명",
   "station-data-live-quality": "스테이션 라이브 해상도",
   "station-data-drone-live-quality": "드론 라이브 해상도",
   "station-data-tag": "태그",
   "station-data-droneModel": "드론모델",
   "station-data-droneName": "드론명",
   "station-data-managerSite": "관리현장명",
  "station-data-station-manager-id": "스테이션 관리명",
  "station-data-drone-manager-id": "드론 관리명",
   "station-data-linkDate": "링크일자",

   "station-data-serial-num": "DOCK 시리얼 번호",
   "station-data-drone-serial-num": "드론 시리얼 번호",
   "station-data-dept": "소속",

  //mission data name
  "btn-mission-list": "목록으로",
  "mission-data-site-num": "현장번호",
  "mission-data-site-name": "현장이름",
  "mission-data-way-title": "경로 제목",
  "mission-data-way-file": "경로 파일",
  "mission-data-way-dept": "소속",
  "mission-data-way-registrant": "등록자",
  "mission-data-way-regdate": "등록일",
  "mission-data-way-template-download": "샘플 다운로드",
  "mission-data-total-time": "비행시간",
  "mission-data-total-dist": "비행거리",

  //mission data
  "mission-data-lat": "위도(º)",
  "mission-data-long": "경도(º)",
  "mission-data-alt": "고도(m)",
  "mission-data-vel": "속도(m/s)",
  "mission-data-angle": "카메라(º)",
  "mission-data-action": "동작제어",
  "mission-data-set": "설정",

  "modal-setting-targetHeight": "목표높이(m)",
  "modal-setting-securityTakeoffHeight": "보안이륙높이(m)",
  "modal-setting-rthAltitude": "RTH 고도",
  "modal-setting-rcLostAction": "RC 고장 시",
  "modal-setting-rthMode": "RTH모드",
  "modal-setting-maxSpeed": "최대속도",
  "modal-setting-commanderModeLostAction": "사령관 모드 이상 시",
  "modal-setting-commanderFlightMode": "사령관 비행 모드",
  "modal-setting-commanderFlightHeight": "사령관 비행 높이",



  //button search
  "btn-dept-search": "소속검색",
  "btn-addr-search": "주소검색",

  //site manager data Correct
  "event-data-site-correct-register": "등록/수정",
  "event-data-site-correct-register-date": "등록일 :",
  "event-data-site-correct-last-date": "수정일 :",
  "event-data-site-correct-registrant": "등록자",


  409: "현장 삭제시 현장 영상 및 관련 정보가 같이 삭제됩니다. 삭제하시겠습니까",
  410: "진행중인 현장은 삭제가 불가합니다",
  411: "변경사항을 저장하시겠습니까",
  412: "$현장명$ 정보를 입력해주세요",
  413: "변경사항이 업데이트 되었습니다",
  414: "현장이 생성되었습니다",
  415: "정보를 입력해 주세요",
  416: "$이메일$ 정보를 형식에 맞게 입력해 주세요",

  "event-data-site-correct-range": "현장 영역크기",
  "event-data-site-correct-within-size": "10km 이내",

  //열람가능 소속  popup
  "event-pop-data-site-choose-dept-id": "소속ID",
  "event-pop-data-site-choose-dept-name": "소속명",
  "event-pop-data-site-choose-dept-phone": "연락처",
  "event-pop-data-site-choose-dept-addr": "주소",


  //425: "주소검색",
  //426: "도로명, 건물명, 지번 입력",
  //427: "찾으시는 주소지의 도로명, 건물명, 지번을 입력한 후 검색 버튼을 클릭해 주세요",
  //428: "검색어를 입력해주세요",
  //429: "검색결과(000건)",
  //435: "상세주소를 입력해주세요",
  //436: "주소 입력이 실패하였습니다. 다시 한번 입력해 주세요",


  //live manager data title
  "live-data-channel-id": "채널ID",
  "live-data-channel-server": "서버",

  "live-data-channel-stream-url": "스트림주소",
  "live-data-channel-operator": "촬영자",
  "live-data-channel-operator-dept": "촬영자 소속",
  "live-data-channel-status": "채널상태",
  "live-data-channel-phone": "연락처",

  //live channel status
  "live-data-channel-status-all": "전체",
  "live-data-channel-status-ready": "대기",
  "live-data-channel-status-live": "중계중",
  "live-data-channel-status-recoding": "녹화",
  "live-data-channel-status-error": "에러",
  "live-data-channel-status-delete": "삭제",

  //operation device choose
  "data-operation-device-type": "기기구분",
  "data-operation-device-type-all": "전체",
  "data-operation-device-type-drone": "드론",
  "data-operation-device-type-phone": "휴대폰",
  "data-operation-device-type-cam": "캠코더",


  "live-data-channel-end": "중계종료",

  "live-data-channel-choose-server": "서버선택",
  "live-data-channel-quality": "화질설정",
  "live-data-channel-reservation": "예약일시",
  "live-data-channel-broadcasting": "중계일시",

  //button list
  "btn-list": "목록",


  "live-data-ch-allocation-serial-num": "할당일련번호",

  "live-data-broadcast-start": "할당시작",
  "live-data-broadcast-end": "할당종료",

  //제외 기능
  505: "인코더서버관리",
  506: "서버1",
  507: "서버설정",
  508: "채널예약 유지시간",
  509: "서버로그 저장기간",
  510: "VOD 생성시 생략시간",
  511: "LIVE 영상을 VOD로 변환시 해당 시간만큼 영상의 앞 부분을 삭제",
  512: "1일",
  513: "2일",
  514: "3일",
  515: "4일",
  516: "5일",
  517: "6일",
  518: "7일",
  519: "30일",
  520: "60일",
  521: "90일",
  522: "180일",
  523: "설정을 저장하시겠습니까",
  524: "설정 적용",
  525: "인코더 목록",
  526: "인코더명",
  527: "서버IP",
  528: "서버상태",
  529: "데몬상태",
  530: "메모리",
  531: "데몬 재시작",
  532: "실행",
  533: "서버 로그보기",
  534: "인코딩 서버로그",
  535: "검색어를 입력해주세요",


  //vod manager data
  "vod-data-thum": "썸네일",
  "vod-data-status": "삭제여부",
  "vod-data-title": "영상제목",
  "vod-data-registered-date": "등록일",
  "vod-data-recording-time": "촬영시각",

  "vod-data-status-delete-view-chk": "삭제된 영상보기",


  //vod detail
  "vod-detail-tab-title-contents": "영상데이터",

  582: "삭제하시겠습니까?",
  590: "파일 등록에 실패하였습니다",


  //트랜스코딩 내역
  "tc-data-file-id": "파일 ID",
  "tc-data-file-title": "제목",

  //file-status
  "tc-data-file-status-all": "전체",
  "tc-data-file-status-processing": "처리중",
  "tc-data-file-status-success": "성공",
  "tc-data-file-status-fail": "실패",

  "tc-data-file-thum": "썸네일",
  "tc-data-file-quality": "화질",
  "tc-data-file-play-time": "재생시간",
  "tc-data-file-status": "상태",

  "tc-data-file-request": "재요청",
  "btn-play": "실행",
  "tc-data-file-regdate": "등록일",

  //제외 페이지
  616: "트랜스코딩서버관리",
  617: "서버1",
  618: "서버 설정",
  619: "트랜스코딩 로그 저장기간",
  620: "30일",
  621: "60일",
  622: "90일",
  623: "180일",
  624: "서버 상태로그 저장기간",
  625: "설정 적용",
  626: "설정을 저작하시겠습니까?",
  627: "트랜스코더명",
  628: "서버IP",
  629: "서버상태",
  630: "데몬상태",
  631: "메모리",
  632: "데몬 명령어",
  633: "시작",
  634: "중지",
  635: "데몬 재시작",
  636: "실행",



  //device manager
  "device-data-id": "디바이스 ID",
  "device-data-serial-num": "개체 시리얼번호",
  "device-data-manufacturer": "제조사",
  "device-data-model-name": "모델명",
  "device-data-device-name": "디바이스명",
  "device-data-approval-num": "형식승인번호",

  "device-data-manager-event-name": "현장명",
  "device-data-manager-dept-name": "소속",
  "device-data-request": "요청상태",
  "device-data-work": "작업상태",
  "device-data-manager-name": "담당자",
  "device-data-manager-phone-num": "연락처",
  "device-data-manager-operator": "조종사",
  "device-data-manager-flight-num": "비행횟수",


  "device-data-start-flight-time":"이륙시간",
  "device-data-end-flight-time":"착륙시간",
  "device-data-working-flight-time":"비행시간",
  "device-data-working-last-flight-time":"마지막 비행시간",


  "device-data-photo": "기기사진",
  "device-data-device-regdate": "등록일",

  "btn-duplicate": "중복검색",

  "device-data-image": "이미지",
  "device-data-default-img": "기본 이미지",
  "device-data-upload-img": "직접등록",

  "device-data-live-status":"라이브 현황",
  "device-data-drone-status":"운용중인 드론 현황",


  "btn-upload" : "업로드",
  "btn-find" : "찾아보기",


  679: "드론정보를 삭제하시겠습니까",
  680: "$디바이스명$을 입력해주세요",

  //station data
  "station-data-name": "스테이션",
  "station-data-schedule-title": "스케줄 제목",
  "station-data-mission-type": "미션종류",
  "station-data-flight-type": "비행종류",
  "station-data-flight-time": "비행시간",

  //serial number double check alert
  "alert-message-check-double-serial-num": "중복된 번호입니다",
  "alert-message-check-is-insert-serial-num": "값을 입력 하세요",
  "alert-message-check-serial-num-confirm": "등록이 가능한 번호 입니다",

  "search-flight-start-date": "비행시작일",


  //디바이스 사용이력
  "device-data-flight-start": "비행시작",
  "device-data-flight-end": "비행종료",
  "device-data-log-create-date": "로그생성일",


  //user type
  "user-data-authority": "권한",
  "user-data-type-admin": "관리자",
  "user-data-type-manager": "운영자",
  "user-data-type-user": "일반",

  //사용 가능한 앱
  "user-data-app-available": "앱 권한",
  "user-data-app-controller": "컨트롤러",
  "user-data-app-viewer": "뷰어",
  "user-data-app-streamer" : "스트리머",

  //user status
  "user-data-status": "상태",
  "user-data-status-update": "상태변경",
  "user-data-status-waiting": "승인대기",
  "user-data-status-used": "승인완료",
  "user-data-status-not-used": "이용정지",
  "user-data-status-inactivate": "휴면",
  "user-data-status-withdrawal": "탈퇴",

  //user manager data
  "user-data-num": "사용자 번호",
  "user-data-name": "이름",
  "user-data-email-id": "아이디(이메일)",
  "user-data-dept-name": "소속",
  "user-data-dept-select": "선택",
  "user-data-phone-num": "연락처",
  "user-data-email": "이메일",
  "user-data-regdate": "등록일",
  "user-data-confirm": "회원승인",
  "user-data-confirm-submit": "승인",
  "user-data-work": "담당업무",
  "user-data-pw": "비밀번호",
  "user-data-last-login-date": "최근접속정보",

  "user-data-check-in-num": "참여횟수",
  "user-data-check-out-memo": "특이사항",

  "user-data-start-time": "시작시간",
  "user-data-end-time": "종료시간",
  "user-data-working-time": "참여시간",

  "btn-pw-reset":"비밀번호 초기화",



  //point data
  "point-data-type" : "구분",
  "point-data-list" : "전체 목록",
  "point-data-add" : "위치점 추가",
  "point-data-registrant" : "등록자",
  "point-data-memo" : "참고사항",
  "point-data-regdate" : "등록일",
  "point-data-check-in": "체크인",


  //livemap data
  "livemap-data-list-title" : "전체 목록",
  "livemap-data-details-title" : "상세보기",
  "livemap-data-way-name" : "경로명",
  "livemap-data-way-dept" : "소속",
  "livemap-data-way-operator" : "조종자",
  "livemap-data-way-drone" : "모델명",
  "livemap-data-way-tic-pic" : "열화상 사진",
  "livemap-data-way-regdate" : "등록일",
  "livemap-data-way-startdate" : "시작일",
  "livemap-data-way-waypointId" : "일련번호",
  "livemap-data-back-to-list" : "목록으로",



  //mission data
  "mission-data-create-title":"정보 입력",
  "mission-data-title":"웨이포인트 명",
  "mission-data-station":"스테이션 선택",
  "mission-data-setting-title":"기본 설정",
  "mission-data-setting-altitude":"비행고도",
  "mission-data-setting-speed":"비행속도",
  "mission-data-setting-clear":"비행 종료 후",
  "mission-data-setting-remark":"비고",
  "mission-data-setting-exit-on-rc-lost":"연결 끊길 경우",


  //mission vod
  "missionvod-data-mission-list":"미션 목록",
  "missionvod-data-mission-vod-list":"미션별 VOD 목록",
  "missionvod-data-mission-name":"경로 제목",
  "missionvod-data-mission-register":"등록자",
  "missionvod-data-mission-dept":"소속",
  "missionvod-data-mission-regdate":"등록일",
  "missionvod-data-mission-drone-name":"디바이스명",
  "missionvod-data-mission-operation":"조종자",

  //mapping data
  "mapping-data-create-title":"정보 입력",
  "mapping-data-title":"매핑 명",
  "mapping-data-setting-altitude":"비행고도",
  "mapping-data-setting-speed":"비행속도",
  "mapping-data-setting-clear":"비행 종료 후",
  "mapping-data-setting-remark":"비고",
  "mapping-data-way-dept": "소속",
  "mapping-data-way-registrant": "등록자",
  "mapping-data-mapping-type" : "타입",
  "mapping-data-mapping-type-squre" : "사각형",
  "mapping-data-mapping-type-polygon" : "다각형",

  "mapping-data-alt": "고도(m)",
  "mapping-data-vel": "속도(m/s)",
  "mapping-data-angle": "카메라(º)",
  "mapping-data-path-angle" : "경로 각도" ,
  "mapping-data-path-interval" : "경로 간격",
  "mapping-data-overlab" : "오버랩",
  "mapping-data-center" :" 중앙 위치",
  "mapping-data-camera" : "카메라 종류",

  //mapping vod




  "user-data-info-pw-setting": "초기 비밀번호는 사용자의 이메일 앞4자리+연락처 뒷자리4+!(느낌표)로 설정됩니다",

  763: "사용자정보를 삭제하시겠습니까",
  766: "$이름$ 정보를 입력해주세요",
  767: "등록이 가능한 아이디 입니다",
  768: "$이메일$ 정보를 형식에 맞게 입력해 주세요",


  //user status select explanation
  "user-data-status-select-title":"사용자 선택",
  "user-data-status-select-name":"상태명",
  "user-data-status-select-explanation":"설명",

  "user-data-status-waiting-explanation": "서비스 이용불가(회원가입은 했으나 관리자가 서비스 이용을 승인하지 않은 상태)",
  "user-data-status-used-explanation": "정상적인 서비스 이용 가능(관리자가 서비스 이용을 승인한  상태)",
  "user-data-status-not-used-explanation": "서비스 이용불가(관리자가 사용자의 서비스 이용을  중단시킨 상태)",
  "user-data-status-inactivate-explanation": "서비스 이용 가능(서비스 로그인이 1년간 없는 상태로 사용자가 바로 해제 가능)",
  "user-data-status-withdrawal-explanation": "서비스 이용 불가(사용자가 직접 탈퇴한 상태로 재가입해야 서비스 이용 가능)",

  //user authority select explanation
  "user-data-authority-select-title": "권한 안내",
  "user-data-authority-select-name": "권한명",

  "user-data-authority-admin-explanation" : "시스템 운영 및 데이터 관련 메뉴 접근 가능",
  "user-data-authority-manager-explanation" : "현장운영 관련 메뉴 접근 가능",
  "user-data-authority-user-explanation" : "현장별 상황판, 시스템 공지만 접근 가능",



  //dept status
  "department-date-used": "사용",
  "department-date-stop": "중지",


  //department manager
  "department-date-id": "소속ID",
  "department-date-name": "소속명",
  "department-date-phone": "연락처",
  "department-date-addr": "주소",
  "department-date-status": "상태",
  "department-date-regdate": "등록일",

  //dept delete alert
  "alert-message-check-detp-delete": "해당 소속정보를 삭제하시겠습니까",
  "alert-message-check-dept-delete-fail": "해당 소속에 속한 사용자가 있을 경우 삭제가 불가합니다",

  //user access-log service
  "user-data-log-service": "이용서비스",
  "user-data-log-controller-app": "컨트롤러",
  "user-data-log-viewer-app": "뷰어",
  "user-data-log-manager-web": "매니저",

  //user access-log type
  "user-data-log-user-type": "회원구분",
  "user-data-login-date": "접속일",
  "user-data-logout-date" : "접속종료일",


  850: "죄송합니다. 현재 찾으시는 페이지를 찾을 수 없습니다. 새로고침하거나 이전 페이지로 이동 후 다른 페이지를 클릭해주세요.",
  851: "이전페이지로 이동",
  852: "홈으로 이동",
  853: "죄송합니다. 현재 시스템 응답이 원활하지 않습니다. 오류가 지속적으로 발생할 경우 관리자에서 문의해주시기 바랍니다.",
  854: "홈으로 이동",
  855: "Share+ 회원가입이 승인 완료되었습니다.",
  856: "로그인 후 이용해 주세요",
  857: "아이디",
  858: "회원가입일",
  859: "가입승인일",
  860: "홍길동님, 비밀번호 재설정 안내 드립니다.",
  861: "아래 버튼을 클릭하여 share+의 비밀번호를 재설정 해주세요",
  862: "아래 버튼은 2021.01.01 21:21:00 ~ 2021.01.02 21:20:59 까지만 유효합니다",
  863: "아래 버튼이 동작하지 않으실 경우에는 http://www.aaa.bb.kk 링크 주소를 복사해서 인터넷 주소창에 입력해주세요",
  864: "비밀번호 재설정하기",
  865: "뷰어앱 현장 링크가 복사되었습니다.",

  //alert message
  "alert-message-process-save": "진행 상황이 저장되었습니다.",
  "alert-message-characters-over": "메시지 길이가 200자를 초과하였습니다.",
  "alert-message-enter": "메시지를 입력해주세요.",
  "alert-contents-enter": "내용을 입력해주세요.",
  "alert-message-date-choose": "날짜를 선택해 주세요",
  "alert-message-date-check": "검색 기간을 확인해 주세요.",
  "alert-message-file-upload-check": "파일 선택 후 업로드해주세요.",
  "alert-message-withdrawal-user": "탈퇴한 회원입니다.",
  "invalid-message-id-or-pw": "가입하지 않은 아이디 혹은 잘못된 비밀번호입니다.",
  "alert-pw-change-fail": "비밀번호 변경에 실패했습니다.",

  "alert-message-choose-site": "현장이 선택되지 않아 현장중심으로 이동할 수 없습니다.",

  "alert-message-check-regdate": "등록일을 확인해주세요.",
  "alert-message-check-start": "비행시작일을 확인해주세요.",
  "alert-message-check-enter-date": "접속일을 확인해주세요.",
  "alert-message-delete": "삭제되었습니다.",
  "alert-message-delete-contents-message": "컨텐츠를 삭제 하시겠습니까?",
  "alert-message-restore-contents-message": "컨텐츠 삭제를 취소하시겠습니까?",
  "alert-message-download-contents-player-message": "컨텐츠를 다운로드 하시겠습니까?",
  "alert-message-download-contents-capture-message": "컨텐츠를 캡처 하시겠습니까?",
  "alert-message-delete-device-detail": "기기정보를 삭제하시겠습니까?",
  "alert-message-delete-mission-detail": "미션정보를 삭제하시겠습니까?",
  "alert-message-update-mission-detail": "미션정보를 수정하시겠습니까?",
  "alert-message-check-device-serial-num": "개체 시리얼번호를 입력해 주세요.",
  "alert-message-check-device-duplicate": "개체 시리얼번호 중복검색을 해주세요",
  "alert-message-check-device-manufacture": "제조사를 선택해주세요.",
  "alert-message-check-device-model": "모델명을 선택해 주세요.",
  "alert-message-check-device-device-name": "디바이스명을 입력해주세요.",
  "alert-message-check-dept-name": "담당소속을 입력해주세요.",
  "alert-message-check-dept-name-value": "소속 이름을 입력해주세요.",
  "alert-message-check-dept-manager": "담당자를 입력해주세요.",
  "alert-message-check-call": "연락처를 입력해주세요.",
  "alert-message-data-save": "저장되었습니다.",
  "alert-message-check-auth-dept-list": "열람가능소속이 없습니다.",
  "alert-message-content-restored": "컨텐츠가 복원되었습니다.",

  "alert-message-dormant-release":"휴면 해제가 완료되었습니다.",
  "alert-message-dormant-release-not":"휴면 해제가 완료되지 않았습니다. 다시 시도하거나 관리자에게 문의 부탁드립니다.",

  "alert-message-check-name": "이름을 입력해 주세요",
  "alert-message-check-phone-num": "핸드폰번호를 입력해 주세요",
  "alert-message-check-pw": "비밀번호를 입력해 주세요",
  "alert-message-check-pw-not-correct": "비밀번호가 다릅니다.",
  "alert-message-check-pw-format": "비밀번호를 형식에 맞게 작성해 주세요.",

  "alert-message-check-id": "아이디(이메일) 정보를 입력해 주세요",
  "alert-message-check-pw-confirm": "비밀번호 재확인 정보를 입력해 주세요",

  "alert-message-check-phone-format": "핸드폰 정보를 형식에 맞게 입력해 주세요",
  "alert-message-check-already-id": "해당 이메일 계정으로 가입된 정보가 있습니다. 취소 버튼 클릭 후 로그인을 이용해 주세요.",


  "alert-message-check-event-name": "현장명을 입력해 주세요.",
  "alert-message-check-event-range": "영역 크기를 확인해주세요.",
  "alert-message-check-addr": "주소를 입력해주세요.",
  "alert-message-check-dept": "소속을 선택해주세요.",
  "alert-message-check-pw-characters": "비밀번호는 6자리 이상입니다.",
  "alert-message-check-double-id": "아이디 중복확인을 해주세요.",
  "alert-message-check-id-characters": "아이디를 4자리 이상 입력해주세요.",

  "alert-message-check-phone-characters": "핸드폰번호 11자리를 입력해주세요",

  "alert-message-check-email-format": "이메일을 형식에 맞게 작성해 주세요.",
  "alert-message-check-user-info-upload" : "개인정보가 수정되었습니다.",
  "alert-message-check-user-info-create" : "개인정보가 저장되었습니다.",

  "alert-message-check-user-status-confirm" : "회원 상태가 승인완료되었습니다.",

  "alert-message-app-usage-limit-exceeded" : "앱 사용 가능 개수가 초과되었습니다.",

  "alert-message-check-title": "제목을 입력해 주세요.",
  "alert-message-check-mission-title": "경로 제목을 입력해 주세요.",
  "alert-message-check-mission-height": "높이를 입력 하세요.",
  "alert-message-check-mission-speed": "비행 속도를 입력 하세요.",
  "alert-message-check-mission-location": "경로가 없습니다.",
  "alert-message-check-mission-polygon": "매핑 폴리곤이 없습니다.",
  "alert-message-check-mission-speed-value" : "비행 속도 제한을 체크해주세요.",
  "alert-message-check-mission-altitude-value" : "비행 높이 제한을 체크해주세요.",


  // teixon added
  "text-unknown" : "알 수 없음",
  "text-unassigned" : "할당 되지 않음",

  //region event registered
  "event-register-alert-changeStatus" : "상태 정보가 변경 되었습니다. 진행 하시겠습니까?",
  "event-register-confirm-want-to-delete": "현장 삭제시 현장 영상 및 관련 정보가 같이 삭제됩니다. 삭제하시겠습니까?",
  //endregion

  "notice-register-textarea-placeholder-text" : "메시지 길이는 최대 200자까지/ 사진은 1개씩 전송가능(jpg, gif)",
  "notice-delete-confirm-text" : "공지를 삭제 하시겠습니까?",
  "notice-delete-content-text" : "내용이 삭제 되었습니다.",
  "login-system-title-text" : "드론 운영 관제 시스템",
  "login-checkbox-id-password-save-text" : "아이디/비밀번호 저장",
  "login-error-fail-login": "아이디 혹은 패스워드가 잘못 되었습니다",
  "login-error-have-not-signed" : "가입하지 않은 아이디 혹은 잘못된 비밀번호입니다",
  "login-error-retired" : "탈퇴한 회원입니다.",

  "point-register-textarea-placeholder-text":"메시지 길이는 최대 200자까지 입력할 수 있습니다.",

  "channel-device-type-drone-name" : "드론",
  "channel-device-type-mobile-name" : "휴대폰",
  "channel-device-type-cam-name" : "캠코더",
  "channel-device-type-station-name" : "스테이션",
  "channel-device-type-stationdrone-name" : "스테이션드론",

  "ready-live" : "영상신호없음",
  "no-live-channel" : "LIVE 채널 할당없음",
  "ready-vod" : "VOD준비중",

  "point-delete-alert-title" : "중요 위치점을 삭제 하시겠습니까?",

  "point-type-name-home":"본부",
  "point-type-name-search":"수색",
  "point-type-name-caution":"주의",
  "point-type-name-target":"목표",
  "point-type-name-danger":"위험",
  "point-type-name-station":"스테이션",

  "event-modify-status-change-alert":"상태가 종료된 현장에서 상태 변경이 불가능 합니다.",
  "event-modify-status-change-open-to-ready-alert":"상태가 오픈된 현장에서 대기 상태 변경이 불가능 합니다.",

  "popup-location-not-found" : "이동 기록이 없습니다.",
  "search-not-found" : "검색 결과가 없습니다.",
  "data-not-found" : "데이터가 없습니다.",
  "user-not-permission" : "권한이 없습니다.",

  //portal
  "portal-button-dronework": "드론워크",
  "portal-button-station": "스테이션",
  "portal-button-site": "전국현황 들어가기",
  "portal-button-system": "시스템관리 들어가기",
};
