const actions = {
  MAP_STATE_INIT({ commit }) {
    commit("MAP_STATE_INIT");
  },

  IS_LEFT_BUTTON_POSITION({ commit }, data) {
    commit("SET_IS_LEFT_BUTTON_POSITION", data);
  },
  IS_RIGHT_ADD_VIDEO_POSTION({ commit }, data) {
    commit("SET_IS_RIGHT_ADD_VIDEO_POSTION", data);
  },

  IS_POSITION_AND_LIEN_SHOW_BUTTON({ commit }, data) {
    commit("SET_IS_POSITION_AND_LIEN_SHOW_BUTTON", data);
  },
  IS_TYPE_CHANGE_SHOW_BUTTON({ commit }, data) {
    commit("SET_IS_TYPE_CHANGE_SHOW_BUTTON", data);
  },
  IS_POINT_SHOW_BUTTON({ commit }, data) {
    commit("SET_IS_POINT_SHOW_BUTTON", data);
  },
  IS_CENTER_SHOW_BUTTON({ commit }, data) {
    commit("SET_IS_CENTER_SHOW_BUTTON", data);
  },
  IS_USER_CENTER_SHOW_BUTTON({ commit }, data) {
    commit("SET_IS_USER_CENTER_SHOW_BUTTON", data);
  },
  IS_NO_FLIGHT_AREA_SHOW_BUTTON({ commit }, data) {
    commit("SET_IS_NO_FLIGHT_AREA_SHOW_BUTTON", data);
  },
  IS_FLIGHT_ALERT_SHOW_BUTTON({ commit }, data) {
    commit("SET_IS_FLIGHT_ALERT_SHOW_BUTTON", data);
  },
  IS_FOLLOW_SHOW_BUTTON({ commit }, data) {
    commit("SET_IS_FOLLOW_SHOW_BUTTON", data);
  },
  IS_POSITION_AND_LIEN_UNDER({ commit }, data) {
    commit("SET_IS_POSITION_AND_LIEN_UNDER", data);
    commit("SET_POSITION_AND_LIEN_UNDER", data ? 76 : 0);
  },
  IS_LNG_LAT_SHOW_UNDER({ commit }, data) {
    commit("SET_IS_LNG_LAT_SHOW_UNDER", data);
    commit("SET_LNG_LAT_SHOW_UNDER", data ? 53 : 0);
  },

  POSITION_AND_LIEN_UNDER({ commit }, data) {
    commit("SET_POSITION_AND_LIEN_UNDER", data);
  },
  LNG_LAT_SHOW_UNDER({ commit }, data) {
    commit("SET_LNG_LAT_SHOW_UNDER", data);
  },

};

export default actions;
