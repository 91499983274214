const actions = {
  DEVICE_INIT({ commit }) {
    commit("DEVICE_INIT");
  },
  SET_DEVICE_DOCK_INFO({ commit }, data) {
    commit("SET_DEVICE_DOCK_INFO", data);
    commit("SET_DOCK_DEBUG_STATUS", data)
  },
  SET_DEVICE_DRONE_INFO({ commit }, data) {
    commit("SET_DEVICE_DRONE_INFO", data);
  },
  DEVICE_DRONE_OFFLINE({ commit }, data) {
    commit("DEVICE_DRONE_OFFLINE", data);
  },
  SET_DEVICES_MISSION_STATUS_INFO({ commit }, data) {
    commit("SET_DEVICES_MISSION_STATUS_INFO", data);
  },
  SET_DEVICES_CMD_EXECUTE_INFO({ commit }, data) {
    commit("SET_DEVICES_CMD_EXECUTE_INFO", data);
  },
  SET_MQTT_STATE({ commit }, data) {
    commit("SET_MQTT_STATE", data);
  },
  SET_CLIENT_ID({ commit }, data) {
    commit("SET_CLIENT_ID", data);
  },
};

export default actions;
