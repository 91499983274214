export default {
  "footer-data-contact-us" : "Contact us",
  "footer-data-company-name": "TEIXON",
  "footer-data-company-tel": "Tel",
  "footer-data-company-email": "Email",

  "btn-login": "Login",
  "btn-sign-in": "Sign up",

  5: "Participate as Guest",

  "login-info-registered-id": "Please enter your registered ID(E-mail)",
  "login-info-registered-pw": "and password.",

  "login-data-id": "ID (E-mail address)",
  "login-data-pw": "Password",
  "login-warning-comment": "Not a member yet?",
  //10: "Auto-login ",

  //13: "Email or password hasn't registered or incorrect ",

  19: "Participate as Guest",
  "20-1": "Please enter Name, Phone number,",
  "20-2": " Department (Division/team) to join as Guest",

  "login-info-available": "This system is only available to authorized users and",
  "login-info-available-illegally": "may be subject to legal sanctions if used illegally.",


  26: "Join",

  //32: "Please enter below form",
  "login-info-approval": "When you apply registration, you can login after approval of administrator. We will inform you through email once your account has been approved. ",

  //38: "(Please enter ",

  "sign-in-info-done": "completed information input! Waiting for approval ",
  "sign-in-info-done-approval": "Registration approval takes 1~2 days ",
  "sign-in-info-done-email":
      "we will send register approval completion details to your registered email",
  "sign-in-info-done-send-email": "once your ID has approved",
  "sign-in-info-done-emergency": "Please contact an administrator if you need emergency approval.",
  "sign-in-info-done-wait": "Waiting for approval ",
  "btn-re-login": "Go to login",
  "btn-find-id" : "Search",

  "find-id-info-enter-data": "Please enter name and phone details if you want to find ID",
  "find-pw-info-enter-data": "Please enter Id,name and phone details if you want to find Password",

  "find-id-title": "Forgot ID",

  "alert-message-not-found-user": "No account found, there's no 'DroneWork' account with the info you provided.",
  "alert-message-manager-question": "Please contact an administrator if the information is not correct. ",

  //71: "Please enter name and phone details if you want to find ID",
  //76: "Please enter your (name) information(details)",
  //77: "Please enter your (phone) information(details)",
  //78: "No account found, there's no 'On-Site' account with the info you provided.",
  //79: "Please contact an administrator if the information is not correct. ",

  "find-id-info-email": "Your ID(Email) you are looking for is",
  "find-id-info-email-2": "",

  "find-id-info-contact-info": "Please contact an administrator if you need entire ID(Email) information",

  "btn-find-pw": "Search",
  "find-pw-title": "Forgot password",

  //86: "Please enter ID, Name, Phone number if you want to find password. ",
  //100: "Please enter ID(Email) details",
  //101: "Please enter name details",
  //102: "Please enter phone number details",

  "alert-message-sending-email": "we sent you to your email about password reset. ",
  "alert-message-confirm-email": "Please confirm email you registered",
  "alert-message-info-find-pw": "you can reset new password by clicking [ reset password] link",

  "btn-login-done" : "Login",
  "alert-message-not-send-email" : "Please contact an administrator if you don't receive mail. ",

  112: "Please enter password to change",

  115: "Please enter at least 8 digits combined at least 2 englishs, numbers, and special symbols.  ",

  116: "Please enter password information (details) ",
  117: "Please enter password as following correct form ",
  118: "Password and reconfirm password do not match. Please re-enter password",

  120: "change password",
  121: "Please contact an administrator if the information is not correct. ",

  "alert-pw-change-complete": "Complete reset password",
  "alert-pw-change-check-key-fail": "Expired URL",
  124: "Please use after login ",

  126: "activate ",

  127: "your account is deactivated due to long term non-use. ",
  128: "Do you want activate your account?",

  130: "Unlock",
  131: "Suspension of use",

  132: "Your account is currently suspended.",
  133: "Please contact an administrator",
  134: "OK",

  142: "Please enter at least 8 digits combined at least 2 englishs, numbers, and special symbols.  ",

  146: "After deactivation account, reapproval will be request once you re-sign up.",
  148: "Are you sure to deactivate account?",
  149: "Administrator can not deactivate account. Please change authority and proceed it. ",


  //변경 json
  "top-select-total-view-dashboard" : "Full status board",
  "top-select-total-drone-status-map": "Drone status map",
  "top-select-total-drone-map-monitoring": "monitoring map",
  "top-select-site-map-monitoring": "monitoring map",
  "top-select-total-view-drone": "Total Check-in Device List",
  "top-select-total-view-vod": "Total VOD List",
  "top-select-total-view-flight-vod": "Total VOD",
  "top-select-total-view-live": "Total LIVE List",
  "top-select-user-view-list": "User List ",
  "top-select-site-view-list": "Site List",
  "top-select-site-view-list-set": "Settings List ",
  "top-select-site-view-list-sort-view-id": "Site ID",
  "top-select-site-view-list-sort-site-name": "Site Name",
  "top-select-site-view-list-sort-manager-department-title": "Department",
  "top-select-site-view-list-sort-reg-date": "Date",

  "all-vod-list-replay-title": "Watch VOD",

  "top-select-site-statistics-site": "Created site",
  "top-select-site-statistics-member": "Login user",
  "top-select-site-statistics-direction": "The site of each region",

  "top-select-site-manager": "Site management",
  "top-select-live-data-ch": "Live management",
  "top-select-live-data-ch-list": "Live Channel List",
  "top-select-station-data": "Station management",

  "top-select-ai-live-data-ch": "AI Live management",
  "top-select-ai-vod-data-list": "AI VOD management",
  "top-select-ai-Streaming-data-list": "AI Streaming",

  "top-select-ai-live-data-detail-ch": "AI LIve Detail",
  "top-select-ai-detail-vod-data-list": "AI VOD Detail",
  "top-select-ai-detail-Streaming-data-list": "AI Streaming Detail",

  "top-select-live-data-details": "LIVE Channel Details",

  "top-select-live-data-monitor": "Live Monitoring ",
  "top-select-live-video-monitor": "Live Monitoring",
  "top-select-live-data-allocation": "Channel allocation ",


  "site-view-mission-title":"Mission management",
  "site-view-mission-choice-title":"Site Mission List",
  "site-view-mission-flight-title":"Site Mission Flight",

  "site-view-contents-title":"Contents management",
  "site-view-contents-choice-title":"Site Contents list",


  "site-view-monitoring-choice-title":"Site list",
  "site-view-monitoring-title":"Site monitoring",

  "top-select-vod-manager-contents": "VOD management ",
  "top-select-vod-manager-tc": "Transcoding details",

  "vod-detail-title-contents": "VOD content details",

  //169: "Transcoder server management ",
  "top-select-device-list": "Device List ",
  "top-select-device-manager": "Device management ",
  "top-select-device-manager-drone": "Drone management",
  "top-select-device-manager-details": "History  ",
  "top-select-user-dept-manager": "User/Department",
  "top-select-user-manager": "User management ",
  "top-select-user-manager-dept": "Department management ",
  "top-select-user-manager-member-log": "User access log",


  "top-select-user-confirm-manager": "User Confirm management",

  //좌측 메뉴
  "lnb-user-mark": "USER",
  "lnb-admin-mark": "ADMIN",

  "overall-main-home": "DASHBOARD",
  "overall-site-control": "SITE CONTROL",
  "overall-site-control-user": "User Home",
  "overall-site-control-Admin": "Home",
  "overall-lnb-site-manage": "Site Management",
  "site-statistics-title": "Statistics",

  //site control 좌측 메뉴
  "site-control-menu-main":"Site DashBoard ",
  "site-control-main-sub-sky-view":"Sky View",
  "site-control-main-sub-info":"Information",

  "site-control-menu-contents":"Contents",
  "site-control-contents-sub-vod":"Drone VOD List",
  "site-control-contents-sub-Comparison":"Comparison VOD",
  "site-control-contents-sub-live":"Live Multi-View",
  "site-control-contents-sub-live-map":"Live Map",
  "site-control-contents-sub-mission-vod":"Mission Plan VOD",

  "site-control-menu-device":"Device Record",
  "site-control-device-sub-drone":"Drone",
  "site-control-device-sub-drone-station":"Drone Station",
  "site-control-ai-monitoring":"AI Monitoring",
  "site-control-device-sub-drone-title":"Drone",
  "site-control-device-sub-flight-title":"Flight Record",

  "site-control-menu-member":"Member Record",

  "site-control-menu-point":"Point",
  "site-control-menu-notice":"Notice",

  "site-control-menu-plan":"Mission Plan",
  "site-control-menu-mission":"Mission Flight",
  "site-control-mission-sub-list":"Waypoint List",
  "site-control-mission-sub-detail":"Waypoint Detail",
  "site-control-mission-sub-update":"Mission Update",
  "site-control-mission-sub-create":"Mission Create",
  "site-control-mission-sub-upload":"Mission Update",
  "site-control-menu-report":"Report",

  "site-control-menu-station-plan":"Station Plan",
  "site-control-menu-schedule":"Schedule",
  "site-control-menu-schedule-create":"Schedule Event Create",
  "site-control-menu-schedule-data-create":"Schedule Data Create",
  "site-control-menu-schedule-data-Update":"Schedule Data Update",
  "site-control-menu-schedule-history":"Schedule History",
  "site-control-menu-schedule-history-Detail":"Schedule History Detail",

  //사용자 홈
  "user-home-event-choice":"List of Site Info",
  "user-home-user-info-title":"User Info",
  "user-home-user-system-notice":"System Notice",
  "user-home-user-system-board-download":"Download File",
  "user-home-user-system-notice-title":"Title",
  "user-home-user-system-notice-contents":"Contents",
  "user-home-user-system-notice-date":"Date",
  "user-home-site-on-progress":"On Progress",
  "user-home-site-sort-order":"Sort Order",
  "user-home-site-sort-by-viewId":"Site Id",
  "user-home-site-sort-by-site-name":"Site name",
  "user-home-site-sort-by-manager-department-title":"Department",

  "user-home-user-version-notice-register-title": "Register App Version",
  "user-home-user-version-notice-info-title": "App Version Info",
  "user-home-user-version-notice-please-input-version": "Please input the version.",
  "user-home-user-version-app-type": "App Type",
  "user-home-user-version-app-version": "Version",
  "user-home-user-version-app-is-latest": "Latest",
  "user-home-user-version-app-is-open": "Open",
  "user-home-user-version-app-open": "Open",
  "user-home-user-version-app-type-viewer": "Viewer",
  "user-home-user-version-app-type-controller": "Controller",
  "user-home-user-version-app-answer-yes": "YES",
  "user-home-user-version-app-answer-no": "NO",
  "user-home-user-version-app-release-note": "Release Note",
  "user-home-user-version-app-please-input-release-note": "Please input release note.",
  "user-home-user-version-app-please-select-app-type": "Please select app type.",
  "user-home-user-version-app-please-input-version": "Please input version.",

  //user title
  "user-info-edit":"User Info Edit",
  "user-detail-title":"User details",
  "user-register-title":"User Registration",
  "user-dept-detail-title":"Department details",
  "user-dept-register-title":"Department Registration",

  "drone-detail-title":"Device details",
  "drone-register-title":"Drone Registration",

  "site-detail-title":"Site details",
  "station-detail-title":"Station details",
  "site-register-title":"Site Registration",

  //175: "Guest access log",
  "btn-logout": "Logout ",

  //mypage txt
  "user-data-pw-change": "Change password ",
  "user-data-pw-confirm": "Confirm password",
  "user-data-pw-input-instruction" : "Please input at least 8 characters by combining two or more Latin characters, numbers, or special characters.",
  "user-data-pw-input-correct-msg" : "Please input password correctly.",

  "top-mypage-pop-confirm-question": "Do you want to change information?",
  "top-mypage-pop-confirm-success": "The information has been successfully saved.",

  //button_txt
  "btn-data-change": "Change",
  "btn-data-patch": "Change",
  "btn-cancel": "Cancel",
  "btn-refresh": "Refresh ",
  "btn-new-browser": "New browser",
  "btn-txt-more": "more",
  "btn-vod-new-browser": "New tab",
  "btn-searching": "Search",
  "btn-reset": "Reset",
  "btn-event-list": "Site List",
  "btn-register": "Register",
  "btn-notice-delete": "Delete",
  "btn-delete": "Delete",
  "btn-site-register": "Site register",
  "btn-confirm": "Confirm",
  "btn-select": "Select",
  "btn-detail": "Details",
  "btn-detail-view": "View",
  "btn-add": "Add",
  "btn-confirm-close": "Close",
  "btn-restore": "Restore",

  //select default choose
  "select-box-default-choose":"--- Please choose ---",


  //dashboard count
  "overall-data-event": "Site",
  "overall-data-event-ea":" ",
  "overall-data-device": "Device",
  "overall-data-register-device": "Device",
  "overall-data-device-ea":" ",
  "overall-data-member": "Member",
  "overall-data-site-member": "Member",
  "overall-data-member-ea":" ",
  "overall-data-live": "Live",
  "overall-data-vod": "VOD",
  "overall-data-num-ea":" ",
  "overall-data-default-ea":" ",
  "overall-data-point": "Point",

  "overall-data-now-device": "now Drone",
  "overall-data-now-member": "now Member",
  "overall-data-now-device-num": "now Drone",
  "overall-data-cnt-log-drone": "Check in Drone",
  "overall-data-cnt-log-vod": "Total VOD",
  "overall-data-cnt-log-member": "Check in Member",

  //data count
  "data-total" : "Total",
  "data-default-ea":" ",
  "data-case-ea":" ",
  "data-vod":" ",
  "data-ch":" ",
  "data-drone-flight":"Device Check-in",
  "data-member-chk-in":"Member Check-in",
  "data-ch-assigned":"Assigned Channels",
  "data-ch-on-air":"On-Air",
  "data-today":" ",


  //현장별 상황판 카운트
  "site-monitoring-home-live-flag":"On Air",
  "site-monitoring-home-data-drone": "Drone",
  "site-monitoring-home-data-member": "Member",
  "site-monitoring-home-data-point": "Point",
  "site-monitoring-home-data-vod": "VOD",
  "site-monitoring-home-data-log-drone": "Flight record",
  "site-monitoring-home-data-log-member": "Movement record",

  "site-monitoring-record-member-list-status-true-name": "Join",
  "site-monitoring-record-member-list-status-false-name": "Close",

  //dashboard_overall
  "overall-count-title": "The Number of items at site that are 'on going'",
  "overall-all-count-title": "The Number of items at site",
  "overall-map-title": "The number of sites by area",
  "overall-event-table-title": "On-site List",
  "overall-event-table-title-dist": "District",
  "overall-event-table-title-event-name": "Site name",
  "overall-event-table-title-user-num": "Member",
  "overall-event-table-title-live-num": "Live",
  "overall-event-table-title-vod-num": "VOD",
  "overall-event-table-title-start-date": "Date",

  "report-drone-flight": "Drone flight report",
  "report-drone-video": "Drone VOD report",

  "overall-drone-title": "List of device working",
  "overall-device-list-title": "Device List",

  "overall-vod-title": "Updated VOD",
  "overall-live-title": "Updated Live",
  "overall-chart-device-title" : "Site device",
  "overall-chart-support-title" : "Site user",
  "overall-chart-event-title" : "Site",

  "vod-list-title": "VOD List",
  "live-map-title": "Live channel location",
  "liveStatus-list-title": "Total live list",


  //search_title
  "search-keyword-title": "Keyword",
  "search-keyword-all": "All",
  "search-regdate-title": "Date ",

  //event(site) status
  "event-data-status-all": "All",
  "event-data-status-ready": "Ready",
  "event-data-status-on-going": "On going",
  "event-data-status-close": "Close",


  //site view title
  "site-view-title-map": "Map",
  "site-view-title-live": "Live",
  "site-view-title-vod" :"Updated VOD",
  "site-view-map-title": "Member's location",
  "site-view-map-check-title": "Display item",
  "site-view-data-recode-title": "Field records",
  "site-view-data-location-Player": "Location Player",


  "site-view-data-recode-click-tabs": "By clicking on each record, you can check the route on the right map.",
  "site-view-data-summary-copy-link": "Copy link",
  "site-view-data-summary-print-btn": "Print",

  //summary text
  "site-view-data-summary-title": "Field Report",

  "site-view-data-summary-period": "Period",
  "site-view-data-summary-charge": "Charge",
  "site-view-data-summary-participation": "Total number of participants",
  "site-view-data-summary-participation-num": "Site participation",
  "site-view-data-summary-location": "Location Information",
  "site-view-data-summary-video": "Video Information",
  "site-view-data-summary-work-list": "Work History",
  "site-view-data-summary-work-date": "Date",
  "site-view-data-summary-work-drone": "Device",
  "site-view-data-summary-work-operator": "Operator",
  "site-view-data-summary-work-flight": "Flights",
  "site-view-data-summary-work-participation-member": "Members",
  "site-view-data-summary-work-live": "Live",
  "site-view-data-summary-work-vod": "VOD",


  //site-view-map-ckeck-title
  "site-view-title-device":"Device",
  "site-view-title-drone":"Drone",
  "site-view-title-operator":"Operator",
  "site-view-title-member":"Member",
  "site-view-title-user":"Member",
  "site-view-title-point":"Point",
  "site-view-title-notice":"Notice",

  //탭 제목
  "site-monitoring-drone-tab-list":"Drone list",
  "site-monitoring-drone-tab-log":"Flight History",

  "site-monitoring-mem-tab-list":"Member List",
  "site-monitoring-mem-tab-log":"Location History",

  "live-map-tab-optics-img" : "Optics Imaging",
  "live-map-tab-thermal-img": "Thermal Imaging",

  "title-detail-info":"Details",


  //map-in-tab-button
  "btn-map-in-tab-current-loc": "Current location ",
  "btn-map-in-moving-route": "Moving route ",

  //드론 비행 알람 팝업
  "flight-alarm-none":"No notice.",

  //site view summary title
  "site-view-summary-event-status": "Current situation",
  "site-view-summary-event-mem": "Current users",
  "site-view-summary-event-vod-num": "",
  "site-view-summary-event-regdate": "Registered date",
  //295: "On-site sharing url ",
  "site-view-summary-event-memo": "Memo",
  "site-view-summary-system-notice": "System Notice",
  "site-view-summary-system-notice-detail": "System Notice",
  "site-view-summary-system-notice-please-input-title": "Please input the title.",
  "site-view-summary-system-notice-please-input-content": "Please input the content.",
  "site-view-summary-system-notice-ask-delete-file": "Are you sure to delete this file?",
  "site-view-summary-system-notice-ask-delete-notice": "Are you sure to delete this system notice?",

  //site-view-weather
  "site-view-weather-title": "Weather information",
  "site-view-weather-update": "Update time",
  "site-view-weather-location": "Location",
  302: "SE",
  303: "SW",
  304: "NE",
  305: "NW",
  306: "E",
  307: "W",
  308: "S",
  309: "N",


  //site-view-notice
  "site-view-notice-title": "Send notification",
  "site-view-notice-img": "Upload image",
  "site-view-notice-edit-text-hint": "message length upto 50 characters / photo only available one",
  "site-view-notice-target": "choose target ",
  "btn-notice-send": "Send",
  "site-view-notice-send-list": "Notification sending details",


  "site-view-notice-table-sendtime": "Sending time",
  "site-view-notice-table-contents": "Content ",
  "site-view-notice-table-picture": "Photo ",
  "site-view-data-remarks" : "Remarks",

  "site-view-notice-img-hint": "W 120px, H 80px",
  "site-view-notice-pop-over-msg": "message length is over 50 characters",
  "site-view-notice-pop-enter-msg": "Please enter massage ",

  //323: "Please select the search area with mouse drag.",

  //site-statistics
  "site-statistics-prev-period": "Previous period",
  "site-statistics-searching-period": "Searching period",
  "site-statistics-legend-compare": "Compare (Before)",
  "site-statistics-title-compare": "Compare (Before)",

  "site-statistics-select-last-1-day": "recent last 1 day",
  "site-statistics-select-last-7-day": "recent last 7 day",
  "site-statistics-select-last-30-day": "recent last 30 day",
  "site-statistics-select-last-90-day": "recent last 90 day",
  "site-statistics-select-yourself": "Select by yourself",

  "site-statistics-title-compare-prev": "Compare to previous cases",
  "site-statistics-column-date": "Date",

  "site-statistics-data-last": "Last ",
  "site-statistics-data-days": " days",
  "site-statistics-data-graph-type": "Choose the graph type",
  "site-statistics-data-graph-bar": "Bar graph",
  "site-statistics-data-graph-pie": "Pie graph",

  "site-statistics-select-start-date": "Start",
  "site-statistics-select-end-date": "End",

  "site-statistics-column-outbreaks": "Number of outbreaks",
  "site-statistics-column-live-time": "Live time",

  "site-statistics-column-all-user": "Number of users",
  "site-statistics-column-mem": "Site member",
  "site-statistics-column-operator": "Operator",
  "site-statistics-column-total": "Total ",


  "site-statistics-legend-seoul": "Seoul City",
  "site-statistics-legend-busan": "Busan City",
  "site-statistics-legend-daegu": "Daegu City",
  "site-statistics-legend-incheon": "Incheon City",
  "site-statistics-legend-gwangju": "Gwangju City",
  "site-statistics-legend-daejeon": "Daejeon City",
  "site-statistics-legend-ulsan": "Ulsan City",
  "site-statistics-legend-sejong": "Sejong City",
  "site-statistics-legend-gyeonggi": "Gyeonggi do",
  "site-statistics-legend-gangwon": "Gangwon-do",
  "site-statistics-legend-chungbuk": "Chungcheongbuk-do",
  "site-statistics-legend-chungnam": "Chungcheongnam-do",
  "site-statistics-legend-jeollabuk": "Jeollabuk-do",
  "site-statistics-legend-jeollanam": "Jeollanam-do",
  "site-statistics-legend-gyeongbuk": "Gyeongsangbuk-do",
  "site-statistics-legend-gyeongnam": "Gyeongsangnam-do",
  "site-statistics-legend-jeju": "Jeju Island",


  //site(event) data name
  "event-data-site-num": "Site number",
  "event-data-site-status": "Status",
  "event-data-site-dist":"District",
  "event-data-site-name": "Site name",
  "event-data-site-addr": "Address",
  "event-data-site-dept": "Department",
  "event-data-site-manager": "Manager",
  "event-data-site-read-restrict": "Reading restrictions",
  "event-data-site-regdate": "Registration Date",
  "event-data-site-phone": "Phone number",
  "event-data-site-email": "Email",
  "event-move-to-this-site": "Move to this site",

  "event-data-site-radio-no-restrict": "No Restrictions",
  "event-data-site-radio-restrict": "Restrictions",

  "event-data-site-shut-down": "Forced shut down",
  "event-data-site-accessible": "Accessible organization",


  //393: "On-site check-in Password",
  //394: "6 digits, controller, viewer app requires password entry when participating as a guest",
  //395: "Hide Site List",
  //396: "Controller, viewer app hiding site list, only accessible through shared url",

  "event-data-site-restrict-info": "Restricting access to information",
  "event-data-site-restrict-description": "Restrict detailed views from situational boards and on-site registration menus.",

   //station data name
   "station-data-stationName": "Station Name",
   "station-data-live-quality": "Station Live Quality",
   "station-data-drone-live-quality": "Drone Live Quality",
   "station-data-tag": "TAG",
   "station-data-droneModel": "Drone Model",
   "station-data-droneName": "Drone Name",
   "station-data-managerSite": "Manager Site",
   "station-data-station-manager-id": "Station Manager Id",
   "station-data-drone-manager-id": "Station Drone Manager id",
   "station-data-linkDate": "Link Date",

   "station-data-serial-num": "DOCK Serial Number",
   "station-data-drone-serial-num": "Drone Serial Number",
   "station-data-dept": "Department",

  //mission data name
  "btn-mission-list": "Mission List",
  "mission-data-site-num": "Site number",
  "mission-data-site-name": "Site name",
  "mission-data-way-title": "Title",
  "mission-data-way-file": "File",
  "mission-data-way-dept": "Department",
  "mission-data-way-registrant": "Registrant",
  "mission-data-way-regdate": "Date",
  "mission-data-way-template-download": "Template Download",
  "mission-data-total-time": "Flight Time",
  "mission-data-total-dist": "Flight Dist",


  //mission vod
  "missionvod-data-mission-list":"Mission List",
  "missionvod-data-mission-vod-list":"Mission VOD List",
  "missionvod-data-mission-name":"Title",
  "missionvod-data-mission-register":"Register",
  "missionvod-data-mission-dept":"Department",
  "missionvod-data-mission-regdate":"Date",
  "missionvod-data-mission-drone-name":"Device name",
  "missionvod-data-mission-operation":"Operation",


  //mission data
  "mission-data-lat": "Lat(º)",
  "mission-data-long": "Lng(º)",
  "mission-data-alt": "Alt(m)",
  "mission-data-vel": "Speed(m/s)",
  "mission-data-angle": "Angle(º)",
  "mission-data-action": "Action",
  "mission-data-set": "Setting",


  "modal-setting-targetHeight": "Target Height(m)",
  "modal-setting-securityTakeoffHeight": "Security Take Off Height(m)",
  "modal-setting-rthAltitude": "RTH Altitude",
  "modal-setting-rthMode": "RTH Mode",
  "modal-setting-rcLostAction": "RC Lost Action",
  "modal-setting-maxSpeed": "Max Speed",
  "modal-setting-commanderModeLostAction": "Commander Mode Lost Action",
  "modal-setting-commanderFlightMode": "Commander Flight Mode",
  "modal-setting-commanderFlightHeight": "Commander Flight Height",

  //button search
  "btn-dept-search": "Search for team",
  "btn-addr-search": "Address search",

  //site manager data Correct
  "event-data-site-correct-register": "Register / Correct",
  "event-data-site-correct-register-date": "Register Date : ",
  "event-data-site-correct-last-date": "Correct Date : ",
  "event-data-site-correct-registrant": "Registered person",



  409: "If you delete the site, the site video and related information will be deleted together. Are you sure you want to delete it?",
  410: "You cannot delete a site that is inprogress",
  411: "Do you want to save your change?",
  412: "Please enter $site name$ information.",
  413: "Your changes have been updated",
  414: "The site has been created.",
  415: "Please enter the information",
  416: "Please enter $email$ information in format",

  "event-data-site-correct-range": "The size of the site",
  "event-data-site-correct-within-size": "Within '10km'",

  //열람가능 소속  popup
  "event-pop-data-site-choose-dept-id": "Department ID",
  "event-pop-data-site-choose-dept-name": "Department name",
  "event-pop-data-site-choose-dept-phone": "Phone number ",
  "event-pop-data-site-choose-dept-addr" : "Address ",


  //425: "search",
  //426: "Enter Road name, building name or land number",
  //427: "Please enter the street name, building name, and location number of the address you are looking for and click the search button.",
  //428: "Please enter keyword",
  //429: "Search result(000)",
  //435: "Please enter address details",
  //436: "Address entry failed. Please enter it again ",


  //live manager data title
  "live-data-channel-id": "Channel ID",
  "live-data-channel-server": "Server",

  "live-data-channel-stream-url": "Stream Url",
  //445: "On-site number",
  "live-data-channel-operator": "Operator",
  "live-data-channel-operator-dept": "Department",
  "live-data-channel-status": "Channel status",
  "live-data-channel-phone": "Phone Number",

  //live channel status
  "live-data-channel-status-all": "All",
  "live-data-channel-status-ready": "Ready ",
  "live-data-channel-status-live": "Live",
  "live-data-channel-status-recoding": "Recoding",
  "live-data-channel-status-error": "Error",

  //live manager operation device choose
  "data-operation-device-type": "Devices",
  "data-operation-device-type-all": "All ",
  "data-operation-device-type-drone": "Drone",
  "data-operation-device-type-phone": "Phone",
  "data-operation-device-type-cam": "Camera",


  "live-data-channel-end": "live end ",

  //471: "channel test",

  "live-data-channel-choose-server": "Choose server",
  "live-data-channel-quality": "Quality setting ",
  "live-data-channel-reservation": "Reservation date",
  "live-data-channel-broadcasting": "Broadcasting time",

  //button list
  "btn-list": "List",

  //ch-allocation data
  "live-data-ch-allocation-serial-num": "Serial number ",

  "live-data-broadcast-start": "Start",
  "live-data-broadcast-end": "End",


  505: "Incorder server management ",
  506: "server1",
  507: "sever setting ",
  508: "channel reservation remaining time ",
  509: "server log ",
  510: "Omit time when creating VOD",
  511: "When converting a live to VOD, delete the front part of LIVE for the corresponding time.",
  512: "1 day",
  513: "2days",
  514: "3days",
  515: "4days",
  516: "5days",
  517: "6days",
  518: "7days",
  519: "30days",
  520: "60days",
  521: "90days",
  522: "180days",
  523: "DO you want to save this setting?",
  524: "Apply",
  525: "Incorder list",
  526: "Incorder name ",
  527: "Server IP ",
  528: "server status ",
  529: "daemon status",
  530: "memory",
  531: "daemon restart",
  532: "start /play / done",
  533: "Server log ",
  534: "incoding server log ",
  535: "Please enter keyword",


  //vod manager data
  "vod-data-thum": "Thumbnail",
  "vod-data-status": "Status",
  "vod-data-title": "VOD title",
  "vod-data-registered-date" : "Registered date ",
  "vod-data-recording-time": "Recording time",

  "vod-data-status-delete-view-chk": "View deleted VOD",

  //vod detail
  "vod-detail-tab-title-contents": "VOD content",


  582: "Do you want to delete it?",
  590: "failed to upload file",

  //tc history
  "tc-data-file-id": "File ID",
  "tc-data-file-title": "File name",

  //file status
  "tc-data-file-status-all": "All",
  "tc-data-file-status-processing": "Processing",
  "tc-data-file-status-success": "Success",
  "tc-data-file-status-fail": "Fail",

  "tc-data-file-thum": "Thumbnail",
  "tc-data-file-quality": "Quality",
  "tc-data-file-play-time": "Play time ",

  "tc-data-file-status": "Status",


  "tc-data-file-request": "Re-request",
  "btn-play": "Play",
  "tc-data-file-regdate": "Registered date",

  //제외 페이지
  616: "transcoding server management",
  617: "server1",
  618: "server setting ",
  619: "transcoding log storage period",
  620: "30days",
  621: "60days",
  622: "90days",
  623: "180days",
  624: "server status log storage period",
  625: "apply",
  626: "DO you want to save this setting?",
  627: "Transcoder name",
  628: "Server IP ",
  629: "server status",
  630: "daemon status",
  631: "memory",
  632: "deamon command",
  633: "start",
  634: "stop ",
  635: "deamon restart",
  636: "play",


  //device manager
  "device-data-id": "Device ID",
  "device-data-serial-num": "Serial number",
  "device-data-manufacturer": "Manufacturer",
  "device-data-model-name": "Model",
  "device-data-device-name": "Device name",
  "device-data-approval-num": "Approval number",

  "device-data-manager-event-name": "Site",
  "device-data-manager-dept-name": "Department",
  "device-data-request": "request",
  "device-data-work": "work",
  "device-data-manager-name": "Manager",
  "device-data-manager-phone-num": "Phone number",
  "device-data-manager-operator":"Operator",
  "device-data-manager-flight-num":"Number of flights",

  "device-data-start-flight-time":"Take-off time",
  "device-data-end-flight-time":"Landing time",
  "device-data-working-flight-time":"Flight time",



  "device-data-photo": "Profile",
  "device-data-device-regdate": "Registered date",


  "btn-duplicate": "Duplicate search",


  "device-data-image": "Image",
  "device-data-default-img": "Default",
  "device-data-upload-img": "Upload",
  "device-data-more": "More",

  "device-data-live-status":"LIVE",
  "device-data-drone-status":"Drones in operation",

  "btn-upload": "Upload",
  "btn-find" : "Find",

  679: "Are you sure you want to delete drone information?",
  680: "Please enter $Device name $",

  "alert-message-dormant-release":"The dormant state has been released.",
  "alert-message-dormant-release-not":"휴면 해제가 완료되지 않았습니다. 다시 시도하거나 관리자에게 문의 부탁드립니다.",

  //station data
  "station-data-name": "Station",
  "station-data-schedule-title": "Schedule Title",
  "station-data-mission-type": "Mission Type",
  "station-data-flight-type": "Flight Type",
  "station-data-flight-time": "Flight Time",

  //serial number double check alert
  "alert-message-check-double-serial-num": "This number has been registed/There is same number ",
  "alert-message-check-is-insert-serial-num": "Please enter value",
  "alert-message-check-serial-num-confirm": "This number is avaliable to register",

  "search-flight-start-date": "Date",

  //디바이스 사용이력
  "device-data-flight-start": "Flight start",
  "device-data-flight-end": "Flight end ",
  "device-data-log-create-date": "Log date",


  //user type
  "user-data-authority": "Authority",
  "user-data-type-admin": "Administrator",
  "user-data-type-manager": "Manager",
  "user-data-type-user": "User",

  //사용 가능한 앱
  "user-data-app-available": "Apps Available",
  "user-data-app-controller": "Controller",
  "user-data-app-viewer": "Viewer",
  "user-data-app-streamer" : "Streamer",

  //user status
  "user-data-status": "Status",
  "user-data-status-update": "Status Update",
  "user-data-status-waiting": "Waiting for approval",
  "user-data-status-used": "Approval done",
  "user-data-status-not-used": "Suspension of Use",
  "user-data-status-inactivate": "Inactivate",
  "user-data-status-withdrawal": "Withdrawal",

  //user manager data
  "user-data-num": "User number",
  "user-data-name": "Name ",
  "user-data-email-id": "ID(E-mail)",
  "user-data-dept-name": "Department ",
  "user-data-dept-select": "Select",
  "user-data-phone-num": "Phone number ",
  "user-data-email": "E-mail",
  "user-data-regdate": "Registered date",
  "user-data-confirm": "Confirm",
  "user-data-confirm-submit": "Confirm",
  "user-data-work": "Responsibility",
  "user-data-pw": "Password",
  "user-data-last-login-date": "Last login",


  "user-data-check-in-num": "Participation",
  "user-data-check-out-memo": "Remarks",

  "user-data-start-time": "Start time",
  "user-data-end-time": "End time",
  "user-data-working-time": "Time to participate",

  "btn-pw-reset":"Reset password",

  "data-none-result": "There is no registered information.",


  //point data
  "point-data-type" : "Type",
  "point-data-list" : "Point list",
  "point-data-add" : "Add Point",
  "point-data-registrant" : "Registrant",
  "point-data-memo" : "Point Memo",
  "point-data-regdate" : "Registered date",
  "point-data-check-in": "Check-in",

  //livemap data
  "livemap-data-list-title" : "Live map list",
  "livemap-data-details-title" : "Live map Details",
  "livemap-data-way-name" : "Name",
  "livemap-data-way-dept" : "Department",
  "livemap-data-way-operator" : "Operator",
  "livemap-data-way-drone" : "Model",
  "livemap-data-way-tic-pic" : "Thermal Imaging",
  "livemap-data-way-regdate" : "Registered date",
  "livemap-data-way-startdate" : "Started date",
  "livemap-data-way-waypointId" : "Waypoint ID",
  "livemap-data-back-to-list" : "Back to list",

  //mission data
  "mission-data-create-title":"Mission registration",
  "mission-data-title":"Title",
  "mission-data-station":"Station",
  "mission-data-setting-title":"Settings",
  "mission-data-setting-altitude":"Altitude",
  "mission-data-setting-speed":"Speed",
  "mission-data-setting-clear":"Upon completion",
  "mission-data-setting-remark":"Remark",
  "mission-data-setting-exit-on-rc-lost":"exitOnRCLost",

  "user-data-info-pw-setting": "The initial password is set as the first 4 digits of the user's email + 4 last digits of the contact number + ! (exclamation mark)",

  763: "Do you want to delete user details?",
  766: "Please enter your $Name$ information",
  767: "This ID can be registered",
  768: "Please enter your $email$ details according to the format",

  //user status select explanation
  "user-data-status-select-title": "User select ",
  "user-data-status-select-name": "Status",
  "user-data-status-select-explanation": "Detail ",

  "user-data-status-waiting-explanation": "Not accessible to the service (joined but not authorized by the administrator yet)",
  "user-data-status-used-explanation": "Accessible to the service normally (authorized by the administrator)",
  "user-data-status-not-used-explanation": "Not accessible to the service (suspended by the administrator)",
  "user-data-status-inactivate-explanation": "Accessible to the service (the latest login was more than a year ago; the user can activate their account by themselves)",
  "user-data-status-withdrawal-explanation": "Not accessible to the service (the user quit by themselves; should rejoin to use the service)",

  //user authority select explanation
  "user-data-authority-select-title": "Authority guide",
  "user-data-authority-select-name": "Authority name ",

  "user-data-authority-admin-explanation" : "Accessible to every menu",
  "user-data-authority-manager-explanation" : "Accessible to menus related to managing events",
  "user-data-authority-user-explanation" : "Accessible only to each event's overview and statistics",
  //789: "Not access ",


  //dept status
  "department-date-used": "Used",
  "department-date-stop": "Stop ",

  //department manager
  "department-date-id": "Department ID",
  "department-date-name": "Department name",
  "department-date-phone": "Phone number",
  "department-date-addr": "Address",
  "department-date-status": "Status",
  "department-date-regdate": "Registered date",

  //dept delete alert
  "alert-message-check-detp-delete": "Do you want to delete your affiliation information?",
  "alert-message-check-dept-delete-fail": "If you have a member who belongs to you, you can't delete it.",


  //user access-log service
  "user-data-log-service": "Service ",
  //832: "All",
  "user-data-log-controller-app": "Controller",
  "user-data-log-viewer-app": "Viewer",
  "user-data-log-manager-web": "Manager",

  //user access-log type
  "user-data-log-user-type": "User type",
  "user-data-login-date": "Access date",
  "user-data-logout-date" : "Access end date",


  850: "Sorry, the page you are looking for is not available. Please refresh or go to the previous page and click on another page.",
  851: "move to previous page ",
  852: "move to home",
  853: "Sorry, the system is currently not responding smoothly. If the error occurs continuously, please contact the manager.",
  854: "move to home",
  855: "Share+ membership has been approved.",
  856: "Please use after login ",
  857: "ID",
  858: "sing up date",
  859: "approval date",
  860: "000 sir, here is your password reset guide",
  861: "Please reset the password for share+ by clicking the button below.",
  862: "The button below is valid only from 2021.01.0121:21:00 to 2021.02 21:20:59",
  863: "If the button below does not work, please copy the link address of http://www.aaa.bb.kk and enter it in the window tab.",
  864: "reset password",
  865: "",

  //alert message
  "alert-message-process-save": "Process are saved.",
  "alert-message-characters-over": "Message is over 200th characters.",
  "alert-message-enter": "Please enter the message.",
  "alert-contents-enter": "Please enter the contents.",
  "alert-message-date-choose": "Please choose date.",
  "alert-message-date-check": "Please check searching date.",
  "alert-message-file-upload-check": "Please choose file first and upload.",
  "alert-message-withdrawal-user": "It is a withdrawal user. ",
  "invalid-message-id-or-pw": "Invalid ID or incorrect password. ",
  "alert-pw-change-fail": "Failed to change password. ",

  "alert-message-choose-site": "Please choose the site.",

  "alert-message-check-regdate": "Please check registered date.",
  "alert-message-check-start": "Please check flight start date.",
  "alert-message-check-enter-date": "Please check the enter date.",
  "alert-message-delete": "It has been deleted.",
  "alert-message-delete-contents-message": "Do you want to delete contents?",
  "alert-message-restore-contents-message": "Do you want to restore this content?",
  "alert-message-download-contents-player-message": "Do you want to download this content?",
  "alert-message-download-contents-capture-message": "Do you want to capture this content?",
  "alert-message-delete-device-detail": "Do you want to delete device details?",
  "alert-message-delete-mission-detail": "Do you want to delete mission details?",
  "alert-message-check-device-serial-num": "Please enter series number.",
  "alert-message-check-device-duplicate": "Please click series number duplicate search.",
  "alert-message-check-device-manufacture": "Please enter manufacture.",
  "alert-message-check-device-model": "Please enter model.",
  "alert-message-check-device-device-name": "Please enter device name",
  "alert-message-check-dept-name": "Please enter department.",
  "alert-message-check-dept-name-value": "Please enter department name.",
  "alert-message-check-dept-manager": "Please enter manager",
  "alert-message-check-call": "Please enter phone number",
  "alert-message-data-save": "It has been saved.",
  "alert-message-check-auth-dept-list": "There is no limited department.",
  "alert-message-content-restored": "This contents has been restored.",

  "alert-message-check-name": "Please enter your name.",
  "alert-message-check-phone-num": "Please enter phone number.",
  "alert-message-check-pw": "Please enter password.",
  "alert-message-check-pw-not-correct": "The password is not correct.",
  "alert-message-check-pw-format": "Please enter password following the format.",

  "alert-message-check-id": "Please enter ID(email) information.",
  "alert-message-check-pw-confirm": "Please enter Password reconfirm information.",

  "alert-message-check-phone-format": "Please enter phone number details(information) as following correct form.",
  "alert-message-check-already-id" : "Your Email has already registered. Please click cancel button and use log-in.",


  "alert-message-check-event-name": "Please enter site name.",
  "alert-message-check-event-range": "Please check the range of site.",
  "alert-message-check-addr": "Please enter address",
  "alert-message-check-dept": "Please choose department.",
  "alert-message-check-pw-characters": "Minimum 6 characters.",
  "alert-message-check-double-id": "Please double check ID.",
  "alert-message-check-id-characters": "Please enter over 4 characters.",

  "alert-message-check-phone-characters": "Please enter over 11 characters ",

  "alert-message-check-email-format": "Please enter proper email format. ",
  "alert-message-check-user-info-upload": "Your personal information has been updated",
  "alert-message-check-user-info-create": "Your personal information has been create",

  "alert-message-check-user-status-confirm" : "This User has been approved.",

  "alert-message-app-usage-limit-exceeded" : "Number of app usage limit exceeded.",

  "alert-message-check-title": "Please enter title.",
  "alert-message-check-mission-title": "Please enter title.",
  "alert-message-check-mission-height": "Please enter height.",
  "alert-message-check-mission-speed": "Please enter speed.",
  "alert-message-check-mission-location": "Please enter flight path.",
  "alert-message-check-mission-speed-value" : "Please check speed.",
  "alert-message-check-mission-altitude-value" : "Please check location.",
  // teixon added
  "text-unknown" : "Unknown",
  "text-unassigned" : "Unassigned",

  //region event register texts
  "event-register-alert-changeStatus" : "Status is changed. Do you want to continue?",
  "event-register-confirm-want-to-delete": "When the site is deleted, the site video and related information are also deleted. do you want to delete?",
  //endregion

  "notice-register-textarea-placeholder-text" : "Maximum 200 characters / photo can be sent one by one(jpg, gif)",
  "notice-delete-confirm-text" : "Are you sure you want to delete the notice?",
  "notice-delete-content-text" : "content has been deleted.",
  "login-system-title-text" : "Drone operation control system",
  "login-checkbox-id-password-save-text" : "ID/PASSWORD SAVE",
  "login-error-fail-login": "ID or password is incorrect",
  "login-error-have-not-signed" : "You have not signed up for an ID or an incorrect password ",
  "login-error-retired" : "You are a retired member",

  "point-register-textarea-placeholder-text":"Maximum 200 characters",

  "channel-device-type-drone-name" : "Drone",
  "channel-device-type-mobile-name" : "Mobile",
  "channel-device-type-cam-name" : "Cam",
  "channel-device-type-station-name" : "Station",
  "channel-device-type-stationdrone-name" : "StationDrone",

  "ready-live" : "Ready",
  "no-live-channel":"No channel assigned",
  "ready-vod" : "Ready",

  "point-delete-alert-title" : "Do you want to delete points?",

  "point-type-name-home":"Central",
  "point-type-name-search":"Search",
  "point-type-name-caution":"Caution",
  "point-type-name-target":"Target",
  "point-type-name-danger":"Danger",
  "point-type-name-station":"Station",

  "event-modify-status-change-alert":"It is not possible to change the status at the site where the status is terminated.",
  "event-modify-status-change-open-to-ready-alert":"It is not possible to change the ready status at the site where the status is open.",

  "popup-location-not-found" : "Not found location.",
  "search-not-found" : "No results were found for your search.",
  "data-not-found":"No data",
  "user-not-permission" : "You do not have permission.",

  //portal
  "portal-button-dronework": "Dronework",
  "portal-button-station": "Station",
  "portal-button-site": "Site Management",
  "portal-button-system": "System Management",
};
