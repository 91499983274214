const state = {
  isLeftPosition : false,
  isRightAddVideoPosition : false,

  defaultShowHeight: 25,
  positionAndLienShowButtonHeight: 35,
  positionAndLienShowLineValue: 10,
  defaultLineValue : 2, //가로 간격
  defaultButtonSize : 34,

  //버튼 show 여부 변수
  isTypeChangeShowButton: false,
  isPositionAndLienShowButton: false,
  isPointShowButton: false,
  isCenterShowButton: false,
  isUserCenterShowButton: false,
  isNoFlightAreaShowButton: false,
  isFlightAlertShowButton: false,
  isFollowShowButton : false,

  //버튼 show 여부 위치 변경
  defaultButtonShowHeight: 10,
  positionAndLienShowButtonTop : 0,
  typeChangeButtonTop: 0,
  pointButtonTop: 0,
  centerButtonTop: 0,
  userCenterButtonTop: 0,
  noFlightAreaButtonTop: 0,
  flightAlertButtonTop : 0,
  followShowButtonTop: 0,

  isPositionAndLienUnder: false,
  isLngLatShowUnder: false,

  positionAndLienUnder : 0,
  lngLatShowUnder : 0,
};

export default state;
