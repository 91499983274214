import Vue from 'vue';

const mutations = {
  DEVICE_INIT(state){
    state.deviceDockInfo = {};
    state.deviceDroneInfo = {};
  },

  SET_DEVICE_DOCK_INFO(state, data){
    if(state.deviceDockInfo[data.sn] === undefined){
      Vue.set(state.deviceDockInfo, data.sn, {})
    }

    if (data.host.modeCode !== null) {
      Vue.set(state.deviceDockInfo[data.sn], "base", data.host)
      return
    }

    if (data.host.jobNumber !== null) {
      Vue.set(state.deviceDockInfo[data.sn], "work", data.host)
      return
    }

    if (data.host.wirelessLink !== null) {
      Vue.set(state.deviceDockInfo[data.sn], "link", data.host)
    }
  },
  SET_DEVICE_DRONE_INFO(state, data){
    Vue.set(state.deviceDroneInfo, data.sn, data.host)
  },
  DEVICE_DRONE_OFFLINE(state, data){
    Vue.set(state.deviceDroneInfo, data.sn, null)
    delete state.deviceDroneInfo[data.sn];
  },
  SET_DEVICES_MISSION_STATUS_INFO(state, data){
    if(data.output.status === "ok" || data.output.status === "failed"){
      delete state.deviceMissionStatus[data.sn];
    } else {
      Vue.set(state.deviceMissionStatus, data.sn, data.output)
    }
  },
  SET_DOCK_DEBUG_STATUS(state, data){
    if (data.host.modeCode === null) {
      return;
    }

    if(state.deviceDockDebugStatus[data.sn] === undefined){
      state.deviceDockDebugStatus[data.sn] = {}
    }

    state.deviceDockDebugStatus[data.sn]["time"] = Date()
    state.deviceDockDebugStatus[data.sn]["droneSn"] = data.host.subDevice.deviceSn;

    let droneIsCoverOpen = {
      status : data.host.coverState === 1 ? true : false,
      progress : 0
    }
    let oldDroneIsCoverOpen = state.deviceDockDebugStatus[data.sn]["droneIsCoverOpen"]
    if(oldDroneIsCoverOpen === undefined || (oldDroneIsCoverOpen.status !== droneIsCoverOpen.status || oldDroneIsCoverOpen.progress !== droneIsCoverOpen.progress)){
      Vue.set(state.deviceDockDebugStatus[data.sn], "droneIsCoverOpen", droneIsCoverOpen)
    }

    let droneIsPutterOpen = {
      status : data.host.putterState === 1 ? true : false,
      progress : 0
    }
    let oldDroneIsPutterOpen = state.deviceDockDebugStatus[data.sn]["droneIsPutterOpen"]
    if(oldDroneIsPutterOpen === undefined || (oldDroneIsPutterOpen.status !== droneIsPutterOpen.status || oldDroneIsPutterOpen.progress !== droneIsPutterOpen.progress)){
      Vue.set(state.deviceDockDebugStatus[data.sn], "droneIsPutterOpen", droneIsPutterOpen)
    }

    let droneIsOnline = {
      status : data.host.subDevice.deviceOnlineStatus,
      progress : 0
    }
    let oldDroneIsOnline = state.deviceDockDebugStatus[data.sn]["droneIsOnline"]
    if(oldDroneIsOnline === undefined || (oldDroneIsOnline.status !== droneIsOnline.status || oldDroneIsOnline.progress !== droneIsOnline.progress)){
      Vue.set(state.deviceDockDebugStatus[data.sn], "droneIsOnline", droneIsOnline)
    }

    let droneIsCharge = {
      status : data.host.droneChargeState.state,
      progress : 0
    }
    let oldDroneIsCharge = state.deviceDockDebugStatus[data.sn]["droneIsCharge"]
    if(oldDroneIsCharge === undefined || (oldDroneIsCharge.status !== droneIsCharge.status || oldDroneIsCharge.progress !== droneIsCharge.progress)){
      Vue.set(state.deviceDockDebugStatus[data.sn], "droneIsCharge", droneIsCharge)
    }

  },
  SET_DEVICES_CMD_EXECUTE_INFO(state, data){
    state;
    data;
    // console.log("SET_DEVICES_CMD_EXECUTE_INFO", data)
  },
  SET_MQTT_STATE (state, mqttState) {
    state.mqttState = mqttState
  },
  SET_CLIENT_ID (state, clientId) {
    state.clientId = clientId
  },
};
export default mutations;
