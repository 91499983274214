const state = {
  deviceDockInfo : {},
  deviceDockDebugStatus : {},
  deviceMissionStatus : {},
  deviceDroneInfo : {},
  devicePayloads : {},
  mqttState : null,
  clientId : null,

  deviceThumbnailInfo : {},
};

export default state;
