<template>
  <div>
    <!-- 240830 신현호 -->
    <!-- router push는 같은경로에서 쿼리파람만 변경후 이동시 push를 못함 -->
    <!-- 페이지네이션 이동을 위해 추가  -->
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
import { mapState } from "vuex";

// import Lnb from "@/components/Lnb";
// import Gnb from "@/components/Gnb";
export default {
  // components: { Lnb, Gnb },
  computed: {
    ...mapState({
      navVisible: state => state.store.navVisible,
      gnbVisible: state => state.store.gnbVisible,
    }),
  },
  mounted() {
    
  },
  methods: {

 },

};
</script>
